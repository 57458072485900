import {
  generateURNByKind,
  WitboostComponent,
  WitboostSystem,
} from '@agilelab/plugin-wb-builder-common';
import pluralize from 'pluralize';
import {
  camelToSpacedCase,
  snakeCaseToTitleCase,
} from '@agilelab/plugin-wb-platform-common';

import { getEntityRelations } from '@backstage/plugin-catalog-react';
import {
  getEntitySourceLocation,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';

import { isOrphan } from '../EntityOrphanWarning';
import { hasCatalogProcessingErrors } from '../EntityProcessingErrorsPanel';
import { hasReservedFieldsError } from '../ReservedFieldsError';
import { ApiHolder, ConfigApi } from '@backstage/core-plugin-api';
import {
  BELONGS_TO,
  isSystemType,
  PRACTICE_SHAPER_ENTITES,
  PROJECT_TYPE_ENTITIES,
} from '@agilelab/plugin-wb-practice-shaper-common';

// TOFIX: use practishaper
// NB: from what I've seen it will require async call, that will require some more changes in calling this
const componentsNames: Record<string, string> = {
  outputport: 'Output Ports',
  workload: 'Workloads',
  storage: 'Storages',
  observability: 'Observability',
  schema: 'Schema',
};

function getLocationTargetHref(
  target: string,
  type: string,
  entitySourceLocation: {
    type: string;
    target: string;
  },
): string {
  if (type === 'url' || target.includes('://')) {
    return target;
  }

  const srcLocationUrl =
    entitySourceLocation.type === 'file'
      ? `file://${entitySourceLocation.target}`
      : entitySourceLocation.target;

  if (type === 'file' || entitySourceLocation.type === 'file') {
    return new URL(target, srcLocationUrl).href;
  }

  return srcLocationUrl;
}

export const catalogDataProductDataView = (
  entity: WitboostSystem,
  componentsList: WitboostComponent[],
  options: {
    configApi: ConfigApi;
    apiHolder: ApiHolder;
    isEditorEnabled?: boolean;
    allowRefresh?: boolean;
  },
) => {
  const componentTitle = (componentType: string) =>
    componentsNames[componentType.toLowerCase()] ||
    pluralize(camelToSpacedCase(componentType));
  const componentsByType = (
    componentsList as WitboostComponent[] | undefined
  )?.reduce((acc, component) => {
    const type = component.metadata.classDetails
      ? component.metadata.classDetails.pluralizedDisplayName ??
        (component.metadata.classDetails.displayName
          ? pluralize(component.metadata.classDetails.displayName)
          : undefined) ??
        componentTitle(component.spec.type)
      : componentTitle(component.spec.type);
    if (!acc[type]) {
      acc[type] = [];
    }

    acc[type].push(
      Object.assign(
        {
          owner: getEntityRelations(component, RELATION_OWNED_BY),
        },
        component,
      ),
    );
    return acc;
  }, {} as Record<string, WitboostComponent[]>);
  const components = Object.keys(componentsByType || {}).map(
    (componentType: any) => {
      return {
        label: componentType,
        components: (componentsByType || {})[componentType] || [],
      };
    },
  );
  let entitySourceLocation:
    | {
        type: string;
        target: string;
      }
    | undefined;
  try {
    entitySourceLocation = getEntitySourceLocation(entity);
  } catch (e) {
    entitySourceLocation = undefined;
  }

  const types: Record<string, any> = {};
  [
    'system',
    'resource',
    'component',
    'api',
    'template',
    'location',
    'group',
  ].forEach(type => {
    const upperType = type.replace(/^./, m => m.toUpperCase());
    types[`is${upperType}`] = entity.kind.toLocaleLowerCase('en-US') === type;
  });
  types.isHeadlessComponent =
    types.isComponent &&
    entity.relations?.find(relation => relation.type === BELONGS_TO) !==
      undefined;
  return {
    entity,
    tags: (entity.metadata?.tags ?? [])
      .concat(entity.spec?.tags ?? [])
      .concat(entity.spec?.mesh?.tags ?? []),
    urn: generateURNByKind(entity.metadata.name, entity.kind),
    isPracticeShaperEntity: PRACTICE_SHAPER_ENTITES.has(entity.kind),
    isResourceType: PROJECT_TYPE_ENTITIES.has(entity.kind),
    isSystemType: isSystemType(entity),
    taxonomyLabel: options.configApi.getString('practiceShaper.taxonomy.label'),
    taxonomies: getEntityRelations(entity, BELONGS_TO),
    targets: (entity?.spec?.targets || [entity?.spec?.target])
      .filter((e: string) => !!e)
      .map((target: any) => ({
        text: target,
        href: getLocationTargetHref(
          target,
          (entity?.spec?.type || 'unknown') as string,
          entitySourceLocation!,
        ),
      })),
    partiallyDeployableIn: (entity.spec?.partiallyDeployableIn || [])
      .map((e: string) => snakeCaseToTitleCase(e))
      .join(', '),
    _relations: {
      ownedByRelations: getEntityRelations(entity, RELATION_OWNED_BY),
      partOfDomainRelations: getEntityRelations(entity, RELATION_PART_OF, {
        kind: 'domain',
      }),
      partOfSystemRelations: getEntityRelations(entity, RELATION_PART_OF, {
        kind: 'system',
      }),
      partOfComponentRelations: getEntityRelations(entity, RELATION_PART_OF, {
        kind: 'component',
      }),
    },
    components,
    _types: types,
    _errors: {
      isOrphan: isOrphan(entity),
      hasCatalogProcessingErrors: hasCatalogProcessingErrors(entity, {
        apis: options.apiHolder,
      }),
      hasReservedFieldsError: hasReservedFieldsError(entity),
    },
    ...options,
  };
};
