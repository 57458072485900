import React, { FC } from 'react';
import {
  ComponentRegister,
  ComponentRegisters,
  parseNunjucks,
} from '@agilelab/plugin-wb-platform-common';
import { useAction, useDataPath } from '../../context';
import _ from 'lodash';

type ComponentEntry = {
  id: string;
  function?: Function;
};

class CustomViewComponentRegister {
  private registry: ComponentRegister;
  constructor() {
    this.registry = ComponentRegisters.getComponentRegister('customView');
  }
  get(componentId: string): Function | undefined {
    return this.registry.get(componentId);
  }

  processMode = (props: Record<string, any>) => {
    const mode = useAction('getMode')() || '';
    return { ...props, mode };
  };

  processDynamicProps = (props: Record<string, any>) => {
    const data = useDataPath('');
    const dynamicProps = ['label', 'title', 'value', 'href'].reduce(
      (acc: any, prop: string) => {
        if (props[prop]) acc[prop] = parseNunjucks(props[prop], data, true);
        return acc;
      },
      {},
    );
    return { ...props, ...dynamicProps };
  };

  processPathValue = (props: Record<string, any>) => {
    const { path } = props;
    const data = useDataPath('');
    if (path) {
      const value = _.get(data, path);
      return { ...props, value };
    }
    if (path === '') return { ...props, value: data };
    return props;
  };

  processHideWhenExists = (props: Record<string, any>) => {
    const { hideWhenExists } = props;
    if (hideWhenExists && _.get(useDataPath(''), hideWhenExists)) return null;
    return props;
  };
  processShowWhenHasValue = (props: Record<string, any>) => {
    if (props.showWhenHasValue && !props.value) return false;
    return true;
  };

  processShowWhenExists = (props: Record<string, any>) => {
    const { showWhenExists } = props;
    if (!showWhenExists) return props;
    const showWhenExistsList =
      typeof showWhenExists === 'string' ? [showWhenExists] : showWhenExists;
    return showWhenExistsList.some((showWhenExistsPath: string) => {
      const obj = _.get(useDataPath(''), showWhenExistsPath);
      if (!obj) return false;
      if (typeof obj !== 'object') return true;
      const allValuesUndefinedOrNull = Object.values(obj).every(
        val => val === undefined || val === null,
      );
      if (allValuesUndefinedOrNull) return false;
      return true;
    });
  };

  processShowWhen = (props: Record<string, any>) => {
    const { showWhen } = props;
    if (showWhen?.value) {
      const data = useDataPath('');
      const parsedValue = parseNunjucks(showWhen.value, data, true);
      const parsedEqual = showWhen.equals;
      const parsedNotEqual = showWhen.notEquals;
      if (parsedEqual !== undefined && parsedValue !== parsedEqual?.toString())
        return null;
      if (
        parsedNotEqual !== undefined &&
        parsedValue === parsedNotEqual?.toString()
      )
        return null;
      if (parsedValue === undefined || parsedValue === null) return null;
    }
    return props;
  };

  processDefaultValue = (props: Record<string, any>) => {
    const { default: defaultValue } = props;
    if (defaultValue && !props.value) {
      return { ...props, value: defaultValue };
    }
    return props;
  };

  renderComponent = (props: Record<string, any>, entry: ComponentEntry) => {
    const Component: FC = entry.function as FC;
    return <Component {...props} />;
  };

  register(entry: ComponentEntry): void {
    this.registry.register({
      id: entry.id,
      function: (props: Record<string, any>) => {
        let processedProps = { ...props };
        processedProps = this.processMode(processedProps);
        processedProps = this.processDynamicProps(processedProps);
        processedProps = this.processPathValue(processedProps);
        processedProps = this.processDefaultValue(processedProps);
        if (
          this.processHideWhenExists(processedProps) === null ||
          !this.processShowWhenExists(processedProps) ||
          this.processShowWhen(processedProps) === null ||
          !this.processShowWhenHasValue(processedProps)
        ) {
          return <></>;
        }
        return this.renderComponent(processedProps, entry);
      },
    });
  }
}

export const CustomViewRegister = new CustomViewComponentRegister();
