import {
  Box,
  Popover,
  useTheme,
  Checkbox,
  FormControlLabel,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { EnumTableFilter } from './types';
import { FiltersButton } from './FilterButtons';
import { FilterInput } from './FilterInput';
import { EnumButtons } from './EnumButtons';
import { isEqual } from 'lodash';
import { WbSearch } from '../WbSearch';

const useStyles = makeStyles(() => ({
  optionLabel: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const EnumFilterValue = <T,>({
  field,
  value,
  renderValue,
}: EnumTableFilter<T>) => {
  const theme = useTheme();

  return (
    <Typography style={{ display: 'flex', alignItems: 'center' }}>
      {field}
      {value && value.length > 0 && (
        <>
          <Typography component="span">:</Typography>
          <Typography
            style={{ fontWeight: '600', marginLeft: '5px' }}
            component="span"
          >
            {value.length > 1 ? (
              <Box
                component="span"
                style={{ display: 'flex', gap: 5, alignItems: 'center' }}
              >
                {renderValue(value[0])}
                <Typography
                  variant="caption"
                  style={{
                    width: 18,
                    height: 18,
                    background: theme.palette.secondary.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '100%',
                    color: 'white',
                    fontSize: 9,
                    fontWeight: 600,
                  }}
                >
                  +{value.length - 1}
                </Typography>
              </Box>
            ) : (
              value.map(v => renderValue(v))
            )}
          </Typography>
        </>
      )}
    </Typography>
  );
};

export const EnumFilter = <T,>(filter: EnumTableFilter<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const [searchValue, setSearchValue] = React.useState('');
  const [options, setOptions] = React.useState(filter.options);
  const [value, setValue] = useState(filter.value || []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onSearch = (search: string) => {
    setSearchValue(search);
    if (filter.onSearch) setOptions(filter.onSearch(search));
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setValue(filter.value || []);
      onSearch('');
    }, 500);
  };
  const onInputClear = () => {
    filter.onChange(undefined);
    setValue([]);
  };
  const onClear = () => {
    setValue([]);
  };
  const onSelectAll = () => {
    setValue(filter.options);
  };

  const onApply = () => {
    filter.onChange(value);
    setTimeout(() => {
      onSearch('');
    }, 500);
    setAnchorEl(null);
  };

  const onSelect = (checked: boolean, option: T) => {
    setValue(values =>
      checked ? values.concat(option) : values.filter(v => !isEqual(v, option)),
    );
  };

  React.useEffect(() => {
    setValue(filter.value || []);
  }, [filter.value]);

  React.useEffect(() => {
    setOptions(filter.options);
  }, [filter.options]);

  return (
    <Box>
      <FilterInput
        onClick={openMenu}
        onClear={onInputClear}
        isOpened={open}
        selected={Boolean(filter.value?.length)}
        hideClear={filter.hideClear}
      >
        <EnumFilterValue {...filter} />
      </FilterInput>

      <Popover
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          style={{ width: '280px', background: 'white', overflow: 'hidden' }}
        >
          {filter.onSearch && (
            <Box
              style={{
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                padding: '8px 16px',
              }}
            >
              <WbSearch value={searchValue} onChange={onSearch} />
            </Box>
          )}

          <EnumButtons
            clear={{
              onClick: onClear,
            }}
            selectAll={{
              onClick: onSelectAll,
            }}
          />

          <Box
            style={{
              maxHeight: '200px',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              padding: '0px 16px',
            }}
          >
            {options.length <= 0 && (
              <Typography style={{ padding: '16px 0px' }}>
                No options
              </Typography>
            )}
            {options.map((option, index) => (
              <FormControlLabel
                classes={{
                  label: classes.optionLabel,
                }}
                key={index}
                label={filter.renderOption(option)}
                control={
                  <Checkbox
                    color="primary"
                    checked={Boolean(value.find(v => isEqual(v, option)))}
                    onChange={(_, checked) => onSelect(checked, option)}
                  />
                }
              />
            ))}
          </Box>

          <FiltersButton
            cancel={{
              onClick: closeMenu,
            }}
            apply={{
              onClick: onApply,
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};
