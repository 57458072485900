import { Link } from '@backstage/core-components';
import { useUserProfile } from '@agilelab/plugin-wb-platform';
import { Avatar, makeStyles, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { SidebarConfig, WbSidebarConfigContext } from '../config';
import { useWbSidebarOpenState } from '../WbSidebarOpenStateContext';

const useStyle = makeStyles(theme => ({
  root: (props: SidebarConfig) => ({
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    backgroundColor: theme.palette.grey[50],
    paddingInline: props.userBadgePadding,
    paddingBlock: '12px',
    gap: '8px',
  }),
  drawerWidth: (props: SidebarConfig) => ({
    width: props.drawerWidthClosed,
  }),
  drawerOpen: (props: SidebarConfig) => ({
    width: props.drawerWidthOpen,
  }),
  avatar: (props: SidebarConfig) => ({
    width: props.userBadgeDiameter,
    height: props.userBadgeDiameter,
  }),
  displayName: {
    color: theme.palette.secondary.dark,
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    display: '-webkit-box',
    wordBreak: 'break-all',
  },
}));

type WbSidebarAvatarProps = { to: string };

export const WbSidebarAvatar = ({ to }: WbSidebarAvatarProps) => {
  const { isOpen } = useWbSidebarOpenState();

  const { sidebarConfig } = useContext(WbSidebarConfigContext);

  const { displayName, profile } = useUserProfile();

  const classes = useStyle(sidebarConfig);

  return (
    <Link
      className={clsx(
        classes.root,
        classes.drawerWidth,
        isOpen && classes.drawerOpen,
      )}
      underline="none"
      aria-label="Profile Settings"
      to={to}
    >
      <Avatar
        alt={`${displayName} profile image`}
        src={profile.picture}
        className={classes.avatar}
      />
      {isOpen && (
        <Tooltip title={displayName}>
          <Typography
            className={classes.displayName}
            variant="body2"
            component="span"
          >
            {displayName}
          </Typography>
        </Tooltip>
      )}
    </Link>
  );
};
