import { CustomError } from '@agilelab/plugin-wb-platform-common';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { CatalogApi } from '@backstage/catalog-client';
import { CustomAlertApi } from '../apis';

export const createRefreshEntityFunction =
  (options: {
    entity?: Entity | null;
    catalogApi: CatalogApi;
    alertApi: CustomAlertApi;
  }) =>
  async () => {
    const { entity, alertApi, catalogApi } = options;
    try {
      if (!entity) return;

      await catalogApi.refreshEntity(stringifyEntityRef(entity));

      alertApi.post({
        message: 'Refresh scheduled',
        severity: 'info',
        timeoutMillis: 5000,
      });
    } catch (errorEntity) {
      alertApi.post({
        error: new CustomError(
          'Refresh failed',
          `Entity ${stringifyEntityRef(entity!)} not found`,
        ),
        severity: 'error',
      });
    }
  };
