import {
  Environment,
  NotificationType,
} from '@agilelab/plugin-wb-platform-common';
import {
  AccessControlAuthorization,
  AccessControlRequest,
  ACLEntity,
  AclEntity,
} from '@agilelab/plugin-wb-access-control-common';

export type GraphIOTypes = 'component' | 'resource';

export enum ProductTraitType {
  Event = 'event',
  SQLView = 'SQLView',
  Raw = 'raw',
  Batch = 'batch',
  Streaming = 'streaming',
}

export enum DataProductStatus {
  Published = 'Published',
  NotPublished = 'NotPublished',
  Archived = 'Archived',
}

export type DataProductEntity = {
  id?: number;
  name: string;
  display_name: string;
  Domain: { id: string; name: string; sub_domain_of: string };
  description: string;
  data_product_owner: string;
  data_product_owner_display_name: string;
  external_id: string;
};

export type DataProductsList = {
  dataProductsInstances: DataProductInstanceEntity[];
};

export type DataProductInstanceEntity = {
  id: number;
  version: string;
  descriptor: Record<string, any>;
  private_descriptor: Record<string, any>;
  published_at: string;
  DataProduct: DataProductEntity;
  InputPorts?: InputPortEntity[];
  Environment: EnvironmentEntity;
};

export type InputPortEntity = {
  Resource?: ResourceEntity;
  DataProductInstance: DataProductInstanceEntity;
  OutputPort: OutputPortEntity | null;
  kind: GraphIOTypes;
};

export type ResourceEntity = {
  name: string;
  description: string;
  display_name: string;
};

export type ReviewEntity = {
  id: number;
  long_comment: string;
  reviewer: string;
  reviewer_display_name: string;
  score: number;
  short_comment: string;
  timestamp: string;
};

export type OutputPort = {
  id: number;
  name: string;
  displayName: string;
  type: ProductTraitType;
  version: string;
  platform: string;
  technology: string;
  description?: string;
  descriptor?: any;
};

export interface ConsumableInterfaceFilters {
  type?: string[];
}

export type OutputPortsList = {
  outputPorts: OutputPortEntity[];
};

export type OutputPortEntity = {
  id: number;
  description: string;
  name: string;
  displayName: string;
  display_name: string;
  fully_qualified_name: string;
  domain: string;
  outputporttype: string;
  version: string;
  descriptor: Descriptor;
  DataProductInstance: DataProductInstanceEntity;
  external_id: string;
  ACLs?: ACLEntity[];
};

export type EnvironmentEntity = {
  id: number;
  name: string;
  priority?: number;
};

export type QuestionEntity = {
  id: number;
  asked_by: string;
  asked_by_display_name: string;
  question: string;
  timestamp: string;
  answer: AnswerEntity;
  DataProductInstance?: DataProductInstanceEntity;
};

export type AnswerEntity = {
  answered_by: string;
  answered_by_display_name: string;
  answer: string;
  timestamp: string;
};

export type AccessAuthorizationEntity = {
  timestamp: string;
  authorized_by: string;
  authorized_by_display_name: string;
  status: string;
};

export type DataProduct = {
  id: number;
  name: string;
  displayName: string;
  domain: string;
  description?: string;
  version: string;
  status: DataProductStatus;
  environment: Environment;
  publishedAt: string;
  lastPublish?: string;
  owner: string;
  ownerDisplayName: string;
};

export type DataProductDetails = {
  rating?: number;
  dependencies?: any;
  reviews?: Review[];
  events?: OutputPort[];
  sqls?: OutputPort[];
  storages?: OutputPort[];
  statusData?: StatusData[];
  observabilities?: Trait[];
  state?: 0 | 1;
  qa?: Question[];
  lastUpdate?: string;
} & DataProduct;

export type Trait = {
  id: number;
  template: string;
  technology: string;
  description: string;
  monitorUrl: string;
  name: string;
  repository: string;
  type: string;
  version: string;
};

export type Question = {
  asked_by: string;
  question: string;
  dataproduct: {
    id: number;
    name: string;
    display_name?: string;
    domain: string;
    version: string;
    environment?: string;
  };
  asked_by_display_name: string;
  id_dataproduct_instance: number;
};

export type Informative = {
  json: string;
  html: string;
  text: string;
  timestamp?: string;
};

export type Answer = {
  answered_by: string;
  answered_by_display_name: string;
  answer: string;
  timestamp?: string;
};

export type Review = {
  score: number;
  reviewer: string;
  reviewer_display_name: string;
  shortComment: string;
  longComment: string;
  timestamp: string;
};

export type StatusData = {
  timestamp: string;
  value: number;
};

export type DomainResolver = {
  domainData: DomainEntity[];
};

export type SelectedItem = {
  type: string;
  kind: string;
  id: number;
  shoppable: boolean;
};

export type Notification = {
  id: number;
  kind: NotificationType;
  notification_request: AccessControlRequest | Question | Informative;
  notification_response: Answer | AccessControlAuthorization | null;
  recipient: string;
  created_at: string;
  updated_at: string;
  read_at: string | null;
  hook_id?: string | null;
  sender: string | null;
};

export type QuestionNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.QUESTION;
  notification_request: Question;
  notification_response: Answer | null;
};

export type InformativeNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.INFORMATIVE;
  notification_request: Informative;
  notification_response: null;
};

export const MAX_ACCESS_REQUEST_TEMPLATE_AVAILABLE = 1;

export type Schema = {
  name: string;
  dataType: string;
  dataTypeDisplay?: string;
  description?: string;
  fullyQualifiedName?: string;
  tags?: Tag[];
  constraint?: string;
  ordinalPosition?: number;
};

export type SchemaList = {
  name: String;
  columns: Schema[];
};

export type Tag = {
  tagFQN: string;
  source: string;
  labelType: string;
  state: string;
  href?: string;
};

export type SemanticLinking = {
  fieldName: string;
  referenceOutputPort: string;
  referenceFieldName: string;
};

export type Descriptor = {
  id: string;
  name: string;
  fullyQualifiedName?: string;
  description: string;
  kind: string;
  version: string;
  infrastructureTemplateId: string;
  useCaseTemplateId?: string;
  dependsOn: string[];
  platform?: string;
  technology?: string;
  outputPortType: string;
  creationDate?: string;
  startDate?: string;
  processDescription?: string;
  dataContract?: {
    schema?: (Schema | SchemaList)[];
    SLA?: {
      intervalOfChange?: string;
      timeliness?: string;
      upTime?: string;
      [key: string]: any;
    };
    termsAndConditions?: string;
    endpoint?: string;
    [key: string]: any;
  };
  dataSharingAgreement: {
    purpose?: string;
    billing?: string;
    security?: string;
    intendedUsage?: string;
    limitations?: string;
    lifeCycle?: string;
    confidentiality?: string;
    [key: string]: any;
  };
  tags: Tag[];
  sampleData?: {
    columns?: string[];
    rows?: Array<string[]>;
  };
  semanticLinking?: SemanticLinking[];
  specific: { [key: string]: any };
  [key: string]: any;
};

// NEW MARKETPLACE MODELS

export type RelationEntity = {
  name: string;
  instanceByTargetInstanceId: DomainEntity;
};

export type InstanceEntity = {
  id: number;
  external_id: string;
  name: string;
  display_name: string;
  kind: string;
  type: string;
  owner: string;
  owner_display_name: string;
  description: string;
  version: string;
  descriptor: Record<string, any>;
  published_at: string;
  shoppable?: ShoppableMode;
  consumable?: ConsumableMode;
  additional_attributes?: Record<string, any>;
  domains: DomainEntity[];
  taxonomy: TaxonomyEntity;
  environment: EnvironmentEntity;
  domain: { data: { name: string } };
  acls?: AclEntity[];
};

export type InstancesList = {
  instances: InstanceEntity[];
};

export type DomainEntity = {
  data: { name: string; external_id: string };
};

export type TaxonomyEntity = {
  name: string;
  external_id: string;
};

export type ComponentSystemDependencyType = {
  data: {
    id: number;
    version: string;
    name: string;
    display_name: string;
    domain: DomainEntity[];
  };
};

export type ComponentDependencyEntity = {
  id: number;
  name: string;
  display_name: string;
  kind: string;
  domain: DomainEntity[];
  system: ComponentSystemDependencyType[];
};

export type InputPortDependencyEntity = {
  component: ComponentDependencyEntity;
};

export type OutputPortDependencyEntity = {
  data: {
    id: number;
    kind: string;
    type: string;
    system: {
      data: {
        id: number;
        version: string;
        environment: EnvironmentEntity;
        name: string;
        display_name: string;
        domain: DomainEntity[];
      };
    }[];
  };
};

export type SystemDependencyEntity = {
  id: number;
  name: string;
  display_name: string;
  domain: DomainEntity[];
  owner: string;
  owner_display_name: string;
  version: string;
  inputPorts: InputPortDependencyEntity[];
  outputPorts: OutputPortDependencyEntity[];
};

export type SystemDependenciesEntities = {
  instances: SystemDependencyEntity[];
};

export enum ConsumableMode {
  Consumable = 'CONSUMABLE',
  HasConsumableChild = 'HAS_CONSUMABLE_CHILD',
}

export enum ShoppableMode {
  Shoppable = 'SHOPPABLE',
  HasShoppableChild = 'HAS_SHOPPABLE_CHILD',
}

export type SystemEntity = {
  data: {
    id: number;
    name: string;
    display_name: string;
    descriptor: Record<string, any>;
    version: string;
    owner_display_name: string;
    domain: DomainEntity[];
    environment: EnvironmentEntity;
  };
};

export type ComponentInstanceEntity = {
  id: number;
  description: string;
  name: string;
  display_name?: string;
  published_at?: string;
  displayName?: string;
  outputporttype: string;
  version: string;
  shoppable?: ShoppableMode;
  consumable?: ConsumableMode;
  descriptor: Descriptor;
  external_id: string;
  kind: string;
  system: SystemEntity[];
  acls?: AclEntity[];
};

export type ComponentInstanceEntities = {
  instances: ComponentInstanceEntity[];
};

export type TotalDataProductInstancesSupervision = {
  instance_aggregate: { aggregate: { count: number } };
};
