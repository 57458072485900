/**
 * Parses optional config
 *
 *
 * @param config - an optional boolean config
 * @returns true if config is true or undefined
 */
export function parseOptionalBooleanConfig(config?: boolean): boolean {
  if (config === undefined) return true;
  return config;
}
