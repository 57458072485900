import React, { ReactNode, ReactElement, useState } from 'react';
import { dumpJsxAsYaml, downloadFile } from '../../utils';
import { CustomViewPages } from './pages';
import { usePermission } from '@backstage/plugin-permission-react';
import BuildIcon from '@material-ui/icons/Build';
import { ContextMenu } from '../ClickMenu';
import { platformCustomViewEditPermission } from '@agilelab/plugin-wb-rbac-common';
import { useAction } from '../../context';
import { Box, IconButton, makeStyles } from '@material-ui/core';

/*
NOTE:
when dumping the segment recorded in the registry, we need to recreate the right conditions, inducing a fake rendering:
the use of two fake components instead of one is due to need attach the right DataRootPathContextProvider
*/
const saveYaml = (filename: string, yamldata: string) => {
  downloadFile(yamldata, `${filename}.yaml`, 'application/yaml');
};

// the total height of the icon
const HEIGHT = 30;

const useStyles = makeStyles(theme => ({
  standardPositioned: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '8px',
  },
  absolutePositioned: {
    position: 'absolute',
    top: `calc(-${HEIGHT}px - ${theme.spacing(1)}px);`,
    right: '10px',
  },
}));

const SegmentDownloader = ({
  children,
  filename,
  done,
}: {
  children: ReactElement;
  filename: string;
  done: Function;
}) => {
  if (children) {
    saveYaml(filename, dumpJsxAsYaml(children));
    done();
  }
  return <></>;
};

export const CustomViewDownloader = ({
  cvchildren,
  included,
  makeSpaceForDownloader,
}: {
  cvchildren: ReactNode;
  included: Array<any>;
  makeSpaceForDownloader?: boolean;
}) => {
  const { id, typeId, templateId } = useAction('getCustomViewInfo')();
  const [dumpSegment, setDumpSegment] = useState<{ fn: Function | undefined }>({
    fn: undefined,
  });
  const [dumpFileName, setDumpFileName] = useState<string>('');
  const { allowed: canEdit } = usePermission({
    permission: platformCustomViewEditPermission,
  });

  const classes = useStyles();

  if (!canEdit) return <></>;

  const menu = [
    {
      label: `Download Default Main Structure ${id}`,
      action: () => {
        saveYaml(
          [id, typeId, templateId].filter(p => !!p).join('_'),
          dumpJsxAsYaml(cvchildren),
        );
      },
    },
  ].concat(
    included.map((inc: any) => ({
      label: `Download Default for ${inc.id}`,
      action: () => {
        setDumpFileName(
          [inc.id, inc.typeId, inc.templateId].filter(p => !!p).join('_'),
        );
        setDumpSegment({
          fn: CustomViewPages.get(inc.id, inc.typeId, inc.templateId),
        });
      },
    })),
  );
  return (
    <Box
      className={
        makeSpaceForDownloader
          ? classes.standardPositioned
          : classes.absolutePositioned
      }
    >
      <ContextMenu menu={menu}>
        <IconButton size="small">
          <BuildIcon />
        </IconButton>
      </ContextMenu>
      <SegmentDownloader
        filename={dumpFileName}
        done={() => setDumpSegment({ fn: undefined })}
      >
        {dumpSegment.fn ? dumpSegment.fn() : undefined}
      </SegmentDownloader>
    </Box>
  );
};
