import { MeshApolloProvider } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Navigate } from 'react-router';
import { HistoricalDataCard } from './HistoricalDataCard';
import { RealTimeDataCard } from './RealTimeDataCard';
import { parseOptionalBooleanConfig } from './utils';
import { Box, useTheme } from '@material-ui/core';

export const MeshSupervisionPage = () => {
  const configApi = useApi(configApiRef);
  const theme = useTheme();

  const enabledMeshSupervision = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.enabled',
    ),
  );

  if (!enabledMeshSupervision) return <Navigate to="/" />;

  return (
    <MeshApolloProvider>
      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: theme.spacing(2) }}
      >
        <RealTimeDataCard />
        <HistoricalDataCard />
      </Box>
    </MeshApolloProvider>
  );
};
