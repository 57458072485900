import React from 'react';
import { useContext, createContext } from 'react';
import _ from 'lodash';

const DataPathContext = createContext(null);

const isDictionary = (v: any) => !Array.isArray(v) && typeof v === 'object';
const isNull = (v: any) => v === undefined || v === null;

export function useDataPath(path: string): any {
  const parent = useContext(DataPathContext);
  if (path === '') return parent;
  const result = _.get(parent, path || '');
  if (isNull(result)) return null;
  if (!!parent && Array.isArray(result)) {
    const arrayResult = (result as Array<any> | Record<string, any>).concat([]); // duplicate array
    arrayResult._parent = parent;
    return arrayResult;
  }
  if (!parent || !isDictionary(result)) return result;
  return Object.assign({}, result, { _parent: parent });
}

export const DataPathContextProvider = ({
  children,
  path,
}: Record<string, any>) => {
  return (
    <DataPathContext.Provider value={useDataPath(path || '')}>
      {children}
    </DataPathContext.Provider>
  );
};

export const DataRootPathContextProvider = ({
  children,
  data,
}: Record<string, any>) => {
  return (
    <DataPathContext.Provider value={data}>{children}</DataPathContext.Provider>
  );
};
