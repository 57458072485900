import React, { ReactNode } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

type WbBreadcrumbsProps = { children: ReactNode };

const useStyles = makeStyles<Theme, { i: number }>(
  theme => ({
    root: {
      height: '40px',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.white,
      '& > ul': {
        listStyle: 'none',
        display: 'flex',
        height: '100%',
        margin: 0,
        padding: 0,
      },
    },
    item: {
      height: '100%',
      color: theme.palette.secondary.main,
      display: 'flex',
      ...theme.typography.body2,
    },
    itemContainer: {
      paddingInline: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    firstItem: {
      backgroundColor: theme.palette.bkg.secondary,
    },
    lastItem: {
      fontWeight: theme.typography.fontWeightMedium as number,
      color: theme.palette.secondary.dark,
    },
    // draws the triangle decorator on the left of each breadcrumb
    decorator: props => ({
      width: 16,
      height: '100%',
      position: 'relative',

      '&:before, &:after': {
        left: 0,
        top: 0,
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
      },

      /* this draws the decorator triangle */
      '&:after': {
        clipPath: 'polygon(0% 0%, calc(100% - 1px) 50%, 0% 100%)',
        visibility: props.i > 0 ? 'visible' : 'hidden',
        backgroundColor:
          props.i === 1 ? theme.palette.bkg.secondary : theme.palette.white,
      },

      /* this draws the border around the decorator triangle */
      '&:before': {
        clipPath:
          'polygon(0% 0%, calc(0% + 1px) 0%, 100% 50%, calc(0% + 1px) 100%, 0% 100%, calc(100% - 1px) 50%)',
        visibility: props.i > 0 ? 'visible' : 'hidden',
        backgroundColor: theme.palette.grey[200],
      },
    }),
  }),
  { name: 'WbBreadcrumbs' },
);

const WbBreadcrumbWrapper = ({
  children,
  i,
  last,
}: {
  children?: ReactNode;
  i: number;
  last?: boolean;
}) => {
  const classes = useStyles({ i });

  return (
    <Box
      component="li"
      className={clsx(
        classes.item,
        i === 0 && classes.firstItem,
        last && classes.lastItem,
      )}
    >
      <Box className={clsx(classes.decorator)} />
      <Box className={clsx(classes.itemContainer)}>{children}</Box>
    </Box>
  );
};

export const WbBreadcrumbs = ({ children }: WbBreadcrumbsProps) => {
  const classes = useStyles({ i: 0 });

  const elements = React.Children.toArray(children);

  const allItems = elements
    .filter(child => {
      return React.isValidElement(child);
    })
    .map((child, index) => (
      <WbBreadcrumbWrapper
        key={index}
        i={index}
        last={index === elements.length - 1}
      >
        {child}
      </WbBreadcrumbWrapper>
    ));

  return (
    <Box component="nav" className={classes.root}>
      <Box component="ul">
        {allItems} {allItems.length === 1 && <WbBreadcrumbWrapper i={1} />}
      </Box>
    </Box>
  );
};
