/**
 * list of recurrent constants
 */

export const DOMAIN = 'dmn';
export const COMPONENT = 'cmp';
export const USER = 'user';
export const GROUP = 'group';
export const DATA_PRODUCT = 'dp';
export const RESOURCE = 'rsr';
export const RELEASE = 'release';
export const SYSTEM = 'system';

export const APP_NAME = 'witboost';
export const CATALOG = 'catalog';

export const URN = 'urn';
export const DMB = 'dmb';

export const MAX_HTTP_PAYLOAD_SIZE_MB = 10;
export const PLATFORM_ENTITY_REF = 'platform:witboost';
export const GUEST_USER_REF = 'user:development/guest';
