import { Drawer, DrawerProps, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { WbCard, WbCardContent } from '../WbCard';
import { useDrawersContext } from './DrawersContext';

const useStyles = makeStyles(_ => ({
  paper: {
    padding: 0,
    width: '80%',
    overflowX: 'hidden',
  },
}));

interface WbDrawerProps extends DrawerProps {
  children?: React.ReactNode;
  open: boolean;
  setOpen: any;
  title: string;
  actions?: JSX.Element;
  cardStyle?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
}

export const WbDrawer: React.FC<WbDrawerProps> = ({
  children,
  title,
  open,
  setOpen,
  contentContainerStyle,
  cardStyle,
  ...drawerProps
}) => {
  const { onCloseAll } = useDrawersContext();

  const classes = useStyles();

  return (
    <Drawer
      disableRestoreFocus
      classes={{
        paper: classes.paper,
      }}
      anchor="right"
      open={open}
      onClose={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onCloseAll ? onCloseAll() : setOpen(false);
      }}
      {...drawerProps}
    >
      <WbCard
        title={title}
        cardStyle={{ height: '100%', ...cardStyle }}
        actions={
          <IconButton
            aria-label="clear"
            onClick={() => setOpen(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        }
      >
        <WbCardContent style={contentContainerStyle || { overflow: 'auto' }}>
          {children}
        </WbCardContent>
      </WbCard>
    </Drawer>
  );
};
