import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import { ReactNode, useContext } from 'react';
import { WbSidebarConfigContext } from '../config';

type WbSidebarScrollWrapperProps = {
  hoverOnlyScrollbar?: boolean;
  children?: ReactNode;
};

const useStyles = makeStyles<
  Theme,
  { hoverOnlyScrollbar: boolean; itemHeight: number }
>(theme => ({
  root: props => ({
    flex: '0 1 auto',
    overflowX: 'hidden',
    width: '100%',

    minHeight: props.itemHeight,
    overflowY: props.hoverOnlyScrollbar ? 'hidden' : 'auto',
    '@media (hover: none)': {
      overflowY: 'auto',
    },
    '&:hover': {
      overflowY: 'auto',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.background.default,
      width: '5px',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.text.secondary,
      borderRadius: '5px',
    },
  }),
}));

export const WbSidebarScrollWrapper = ({
  hoverOnlyScrollbar = false,
  children,
}: WbSidebarScrollWrapperProps) => {
  const {
    sidebarConfig: { itemHeight },
  } = useContext(WbSidebarConfigContext);

  const classes = useStyles({ hoverOnlyScrollbar, itemHeight });

  return <div className={classes.root}>{children}</div>;
};
