import {
  catalogDataProductDataView,
  ControlPanelPage,
  DataContractEditor,
  EditorComponentPageLoader,
  EditorPage,
  EditorPageContextProvider,
  EntityCatalogGraphCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityGroupProfileCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntityMembersListCard,
  EntityOrphanWarning,
  EntityOwnershipCard,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  EntityUserProfileCard,
  hasCatalogProcessingErrors,
  hasReservedFieldsError,
  isComponentType,
  isKind,
  isOrphan,
  ReleasePage,
  ReleasePageContextProvider,
  ReservedFieldsError,
} from '@agilelab/plugin-wb-builder-catalog';
import {
  applyWitboostVersionedEntity,
  RELATION_CAN_EDIT,
  RELATION_EDITABLE_BY,
  RELATION_HAS_MAIN_TEMPLATE,
  RELATION_MAIN_TEMPLATE_OF,
  WitboostSystem,
} from '@agilelab/plugin-wb-builder-common';
import {
  Entity,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { EmptyState } from '@backstage/core-components';
import {
  ApiHolder,
  configApiRef,
  useApi,
  useApiHolder,
} from '@backstage/core-plugin-api';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import { Direction } from '@backstage/plugin-catalog-graph';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage/plugin-github-actions';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import { ProjectPage } from './ProjectPage';
import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import {
  catalogApiRef,
  getEntityRelations,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import {
  createRefreshEntityFunction,
  customAlertApiRef,
  CustomView,
  CustomViewComponent,
  useRefreshPermission,
  WbEnvironmentSelector,
  WbHeaderActions,
} from '@agilelab/plugin-wb-platform';
import { BELONGS_TO } from '@agilelab/plugin-wb-practice-shaper-common';

const techdocsContent = <EntityTechdocsContent />;
const EntityAboutCard = ({ customviewpage }: { customviewpage?: string }) => {
  const { entity } = useEntity();
  const apiHolder: ApiHolder = useApiHolder();
  const configApi = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const { allowRefresh } = useRefreshPermission(entity);
  const refreshEntity = createRefreshEntityFunction({
    entity,
    catalogApi,
    alertApi,
  });

  return (
    <CustomView
      id={customviewpage ? customviewpage : 'builder_entity_content'}
      typeId={String(entity.spec?.type ?? '').toLowerCase()}
      data={catalogDataProductDataView(entity as WitboostSystem, [], {
        apiHolder,
        configApi,
        allowRefresh,
      })}
      actions={{
        refreshEntity: refreshEntity,
      }}
    >
      <CustomViewComponent type="catalog_warnings" />
      <CustomViewComponent type="include" id="builder_system_general" />
    </CustomView>
  );
};
/**
 * Checks whether the provided entity is an headless system
 */
export const isHeadlessComponent = async (entity: Entity) => {
  return (
    entity.kind.toLocaleLowerCase('en-US') === 'component' &&
    entity.relations?.find(relation => relation.type === BELONGS_TO) !==
      undefined
  );
};

function isSystemWithoutType() {
  return (entity: Entity) => {
    if (!isKind('system')(entity)) {
      return false;
    }
    const systemEntity = entity as WitboostSystem;
    return systemEntity && !systemEntity.spec.type;
  };
}

const useEntityPage = () => {
  const { entity } = useEntity();
  const configApi = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);

  const isEditorEnabled = configApi.getOptionalBoolean(
    'catalog.editor.enabled',
  );

  const belongsToTaxonomy = getEntityRelations(entity, BELONGS_TO);

  if (belongsToTaxonomy.length) {
    return { entity, isEditorEnabled };
  }

  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { loading, value: parentEntity } = useAsync(async () => {
    if (partOfSystemRelations?.length)
      return catalogApi.getEntityByRef(partOfSystemRelations[0]);
    return undefined;
  }, [catalogApi]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { entities } = useRelatedEntities(parentEntity!, {
    type: RELATION_HAS_PART,
    kind: 'Component',
  });

  return { entity, loading, parentEntity, entities, isEditorEnabled };
};

const EditorRoute = () => {
  const { entity, loading = false, parentEntity, entities } = useEntityPage();
  const versionedEntity = applyWitboostVersionedEntity(entity);

  const [version, setVersion] = useState(versionedEntity.spec.mesh.version);

  return (
    <>
      {loading ? (
        <EditorComponentPageLoader />
      ) : (
        <EditorPageContextProvider
          entity={entity}
          relatedEntities={entities ?? []}
          parent={parentEntity ?? undefined}
          version={version}
          setVersion={setVersion}
        >
          <EditorPage />
        </EditorPageContextProvider>
      )}
    </>
  );
};

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasReservedFieldsError}>
        <Grid item xs={12}>
          <ReservedFieldsError />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <EntityAboutCard />
    </Grid>
    <Grid item xs={12}>
      <EntityHasSubcomponentsCard />
    </Grid>
  </Grid>
);

const OverViewContent = () => {
  const { entity } = useEntity();
  const apiHolder: ApiHolder = useApiHolder();
  const configApi = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const { allowRefresh } = useRefreshPermission(entity);
  const refreshEntity = createRefreshEntityFunction({
    entity,
    catalogApi,
    alertApi,
  });
  return (
    <CustomView
      id="builder_component"
      typeId={String(entity.spec?.type ?? '').toLowerCase()}
      templateId={(entity.spec?.mesh as any | undefined)?.useCaseTemplateId}
      data={catalogDataProductDataView(entity as WitboostSystem, [], {
        apiHolder,
        configApi,
        allowRefresh,
      })}
      actions={{
        refreshEntity: refreshEntity,
      }}
    >
      <CustomViewComponent type="catalog_warnings" />
      <CustomViewComponent type="include" id="builder_component_general" />
      <CustomViewComponent type="catalog_components" />
    </CustomView>
  );
};

const ServiceEntityPage = () => {
  const { isEditorEnabled } = useEntityPage();
  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        <OverViewContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/ci-cd" title="CI/CD">
        {cicdContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/api" title="API">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6} xs={12}>
            <EntityProvidedApisCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityConsumedApisCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6} xs={12}>
            <EntityDependsOnComponentsCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityDependsOnResourcesCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      {isEditorEnabled && (
        <EntityLayout.Route path="/editor" title="Edit and test">
          <EditorRoute />
        </EntityLayout.Route>
      )}
    </EntityLayoutWrapper>
  );
};

const WebsiteEntityPage = () => {
  const { isEditorEnabled } = useEntityPage();

  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        <OverViewContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/ci-cd" title="CI/CD">
        {cicdContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6} xs={12}>
            <EntityDependsOnComponentsCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityDependsOnResourcesCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      {isEditorEnabled && (
        <EntityLayout.Route path="/editor" title="Edit and test">
          <EditorRoute />
        </EntityLayout.Route>
      )}
    </EntityLayoutWrapper>
  );
};

const OutputPortPage = () => {
  const configApi = useApi(configApiRef);
  const { isEditorEnabled } = useEntityPage();
  const dataContractEditorEnabled = configApi.getOptionalBoolean(
    'mesh.builder.enableDataContractEditor',
  );

  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        <OverViewContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      {isEditorEnabled && (
        <EntityLayout.Route
          path="/editor"
          title="Edit and Test"
          tabProps={{ style: { textTransform: 'none' } }}
          overrideHeaderActions={
            <WbHeaderActions>
              <WbEnvironmentSelector />
            </WbHeaderActions>
          }
        >
          <EditorRoute />
        </EntityLayout.Route>
      )}

      {dataContractEditorEnabled && (
        <EntityLayout.Route
          path="/data-contract-editor"
          title="Data Contract Editor"
        >
          <DataContractEditor />
        </EntityLayout.Route>
      )}
    </EntityLayoutWrapper>
  );
};

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const ComponentDefaultEntityPage = () => {
  const { isEditorEnabled } = useEntityPage();
  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        <OverViewContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      {isEditorEnabled && (
        <EntityLayout.Route path="/editor" title="Edit and test">
          <EditorRoute />
        </EntityLayout.Route>
      )}
    </EntityLayoutWrapper>
  );
};

const HeadlessComponentPage = () => {
  const { isEditorEnabled, entity } = useEntityPage();
  const versionedEntity = applyWitboostVersionedEntity(entity);

  const [_, setVersion] = useState(versionedEntity.spec.mesh.version);

  return (
    <EntityLayoutWrapper isWrappingProject>
      <EntityLayout.Route path="/" title="Overview">
        <OverViewContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      {isEditorEnabled && (
        <EntityLayout.Route
          path="/editor"
          title="Edit and test"
          overrideHeaderActions={
            <WbHeaderActions>
              <WbEnvironmentSelector />
            </WbHeaderActions>
          }
        >
          <EditorRoute />
        </EntityLayout.Route>
      )}

      <EntityLayout.Route path="/deploy" title="Control Panel">
        <ControlPanelPage entity={entity} setVersion={setVersion} />
      </EntityLayout.Route>

      {isEditorEnabled && (
        <EntityLayout.Route path="/release" title="Deployment">
          <ReleasePageContextProvider
            entity={entity}
            relatedEntities={[]}
            setVersion={setVersion}
          >
            <ReleasePage />
          </ReleasePageContextProvider>
        </EntityLayout.Route>
      )}
    </EntityLayoutWrapper>
  );
};

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      <ServiceEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      <WebsiteEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('outputport')}>
      <OutputPortPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isHeadlessComponent}>
      <HeadlessComponentPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <ComponentDefaultEntityPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6} xs={12}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const userPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const groupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard />
        </Grid>
        <Grid item xs={12}>
          <EntityHasComponentsCard />
        </Grid>
        <Grid item xs={12}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityHasResourcesCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const blueprintPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard
            relations={[
              RELATION_PART_OF,
              RELATION_HAS_PART,
              RELATION_HAS_MAIN_TEMPLATE,
              RELATION_MAIN_TEMPLATE_OF,
              RELATION_OWNED_BY,
              RELATION_OWNER_OF,
            ]}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const templatePage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard
            relations={[
              RELATION_CAN_EDIT,
              RELATION_EDITABLE_BY,
              RELATION_MAIN_TEMPLATE_OF,
              RELATION_HAS_MAIN_TEMPLATE,
            ]}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const domainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item xs={12}>
          <EntityHasSystemsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const hierarchyEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        relationPairs={[['readsFrom', 'isReadFrom']]}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
          'readsFrom',
          'isReadFrom',
          'isPartOf',
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const projectPage = <ProjectPage title="Project" />;
export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isSystemWithoutType()} children={systemPage} />
    <EntitySwitch.Case if={isKind('system')} children={projectPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case if={isKind('blueprint')} children={blueprintPage} />
    <EntitySwitch.Case if={isKind('edittemplate')} children={templatePage} />
    <EntitySwitch.Case if={isKind('template')} children={templatePage} />
    <EntitySwitch.Case
      if={isKind('hierarchy')}
      children={hierarchyEntityPage}
    />
    <EntitySwitch.Case
      if={isKind('hierarchyclass')}
      children={hierarchyEntityPage}
    />
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
