import { ApolloError, useQuery } from '@apollo/client';
import { ErrorPanel } from '@backstage/core-components';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Grid,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

import { WbCardContent, WbWidget } from '@agilelab/plugin-wb-platform';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DATA_PRODUCTS_USAGE } from '../../graphql';
import { CustomStatus } from '../CustomStatus/CustomStatus';
import { DpUsageQuery } from './types/dp-usage';

const useStylesMesh = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    bottom: {
      color: theme.palette.grey[100],
    },
    top: {
      position: 'absolute',
      left: 0,
      color: theme.palette.charts[0],
    },
  }),
);

function parseData(data: DpUsageQuery) {
  return {
    used: data.inputPort_aggregate?.aggregate?.count || 0,
    total: data.instance_aggregate?.aggregate?.count || 0,
  };
}

function getPercentage(parsedData: { used: number; total: number }) {
  return ((parsedData.used || 0) / (parsedData.total || 1)) * 100;
}
function MeshCircularProgress(props: CircularProgressProps) {
  const classes = useStylesMesh();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={200}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        className={classes.top}
        size={200}
        thickness={5}
        {...props}
      />
    </div>
  );
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box position="relative" display="inline-flex">
      <MeshCircularProgress value={props.value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" component="div">{`${Math.round(
          props.value,
        )}%`}</Typography>
        <CustomStatus getColor={palette => palette.charts[0]}>
          Consumed
        </CustomStatus>
      </Box>
    </Box>
  );
}

export const UsageKpiCard = () => {
  const theme = useTheme();
  const {
    loading,
    error,
    data,
  }: {
    loading: boolean;
    error?: ApolloError;
    data?: DpUsageQuery;
  } = useQuery(DATA_PRODUCTS_USAGE);

  return (
    <WbWidget
      title="Data Products Usage"
      icon={
        <Tooltip title="Percentage of consumed Data Products on production environment">
          <InfoOutlinedIcon color="primary" />
        </Tooltip>
      }
      cardStyle={{ height: '100%' }}
    >
      <WbCardContent>
        {!!error && <ErrorPanel error={error} />}
        {!!loading && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="35%"
              height="16px"
            />
            <Skeleton
              animation="wave"
              variant="rect"
              width="35%"
              height="16px"
            />
            <Skeleton
              animation="wave"
              variant="circle"
              width="200px"
              height="200px"
              style={{ alignSelf: 'center' }}
            />
          </Box>
        )}
        {!!data && (
          <Grid container>
            <Grid item xs={12}>
              <CustomStatus getColor={palette => palette.charts[0]}>
                Consumed
              </CustomStatus>
            </Grid>
            <Grid item xs={12}>
              <CustomStatus getColor={palette => palette.grey[100]}>
                Not Consumed
              </CustomStatus>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgressWithLabel
                value={getPercentage(parseData(data))}
              />
            </Grid>
          </Grid>
        )}
      </WbCardContent>
    </WbWidget>
  );
};
