/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { LinearProgress } from '@material-ui/core';
import { IChangeEvent } from '@rjsf/core';
import qs from 'qs';
import React, { useCallback, useState, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router';
import useAsync from 'react-use/lib/useAsync';
import { scaffolderApiRef } from '../../api';
import { FieldExtensionOptions } from '../../extensions';
import { SecretsContext } from '../secrets/SecretsContext';
import {
  rootRouteRef,
  scaffolderTaskRouteRef,
  selectedTemplateRouteRef,
} from '../../routes';
import { MultistepJsonForm, reviewStep } from '../MultistepJsonForm';
import { createValidator } from './createValidator';

import { Content, Page } from '@backstage/core-components';
import {
  AnalyticsContext,
  useApi,
  useApiHolder,
  useRouteRef,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { LayoutOptions } from '../../layouts';
import {
  WbBreadcrumb,
  WbBreadcrumbs,
  WbCard,
  WbHeader,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { CustomError } from '@agilelab/plugin-wb-platform-common';
import { CustomFieldValidator } from '../../extensions/types';
import { createValidatorAsync } from './createValidatorAsync';

const useTemplateParameterSchema = (templateRef: string) => {
  const scaffolderApi = useApi(scaffolderApiRef);
  const { value, loading, error } = useAsync(
    () => scaffolderApi.getTemplateParameterSchema(templateRef),
    [scaffolderApi, templateRef],
  );
  return { schema: value, loading, error };
};

export const TemplatePage = ({
  customFieldExtensions = [],
  layouts = [],
}: {
  customFieldExtensions?: FieldExtensionOptions<any, any>[];
  layouts?: LayoutOptions[];
}) => {
  const apiHolder = useApiHolder();
  const secretsContext = useContext(SecretsContext);
  const alertApi = useApi(customAlertApiRef);
  const scaffolderApi = useApi(scaffolderApiRef);
  const { templateName, namespace } = useRouteRefParams(
    selectedTemplateRouteRef,
  );
  const templateRef = stringifyEntityRef({
    name: templateName,
    kind: 'template',
    namespace,
  });
  const navigate = useNavigate();
  const scaffolderTaskRoute = useRouteRef(scaffolderTaskRouteRef);
  const rootRoute = useRouteRef(rootRouteRef);
  const { schema, loading, error } = useTemplateParameterSchema(templateRef);
  const [formState, setFormState] = useState<Record<string, any>>(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    try {
      return JSON.parse(query.formData as string);
    } catch (e) {
      return query.formData ?? {};
    }
  });
  const handleFormReset = () => setFormState({});
  const handleChange = useCallback(
    (e: IChangeEvent) => {
      setFormState(e.formData);
    },
    [setFormState],
  );

  const handleCreate = async () => {
    const { taskId } = await scaffolderApi.scaffold({
      templateRef,
      values: formState,
      secrets: secretsContext?.secrets,
    });

    const formParams = qs.stringify(
      { formData: formState },
      { addQueryPrefix: true },
    );
    const newUrl = `${window.location.pathname}${formParams}`;
    // We use direct history manipulation since useSearchParams and
    // useNavigate in react-router-dom cause unnecessary extra rerenders.
    // Also make sure to replace the state rather than pushing to avoid
    // extra back/forward slots.
    window.history?.replaceState(null, document.title, newUrl);

    navigate(scaffolderTaskRoute({ taskId }));
  };

  if (error) {
    alertApi.post({
      error: new CustomError('Failed to load template', error.message),
      severity: 'error',
    });
    return <Navigate to={rootRoute()} />;
  }
  if (!loading && !schema) {
    alertApi.post({ message: 'Template not found', severity: 'error' });
    return <Navigate to={rootRoute()} />;
  }

  const customFieldComponents = Object.fromEntries(
    customFieldExtensions.map(({ name, component }) => [name, component]),
  );

  /**
   * We need always to put something for each picker validation (despite the fact it is async or not)
   * since we need to perform some a-priori validation, like the fact that a field is required or not.
   */
  const customFieldValidatorsSync = Object.fromEntries(
    customFieldExtensions.map(({ name, validation, asyncValidation }) => [
      name,
      asyncValidation === true
        ? undefined
        : (validation as CustomFieldValidator<any>),
    ]),
  );

  const customFieldValidatorsAsync = Object.fromEntries(
    customFieldExtensions
      .filter(({ asyncValidation }) => asyncValidation)
      .map(({ name, validation }) => [
        name,
        validation as CustomFieldValidator<any>,
      ]),
  );

  return (
    <AnalyticsContext attributes={{ entityRef: templateRef }}>
      <Page themeId="home">
        <WbHeader
          pageTitleOverride={schema?.title}
          breadcrumbs={
            <WbBreadcrumbs aria-label="breadcrumb">
              <WbBreadcrumb>Builder</WbBreadcrumb>
              <WbBreadcrumb to="/create">Templates</WbBreadcrumb>
              <WbBreadcrumb>{schema?.title}</WbBreadcrumb>
            </WbBreadcrumbs>
          }
          title={schema?.title}
        />
        <Content>
          {loading && <LinearProgress data-testid="loading-progress" />}
          {schema && (
            <WbCard title="Template Wizard">
              <MultistepJsonForm
                formData={formState}
                fields={customFieldComponents}
                onChange={handleChange}
                layouts={layouts}
                additionalSteps={[
                  reviewStep(
                    'Review and Create',
                    'Create',
                    formState,
                    schema.steps,
                    handleFormReset,
                    handleCreate,
                  ),
                ]}
                steps={schema.steps.map(step => {
                  return {
                    ...step,
                    asyncValidate: createValidatorAsync(
                      step.schema,
                      customFieldValidatorsAsync,
                      { apiHolder },
                    ),
                    validate: createValidator(
                      step.schema,
                      customFieldValidatorsSync,
                      { apiHolder },
                    ),
                  };
                })}
              />
            </WbCard>
          )}
        </Content>
      </Page>
    </AnalyticsContext>
  );
};
