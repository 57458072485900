import { IconComponent } from '@backstage/core-plugin-api';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { ReactNode, useContext } from 'react';

import {
  WbSidebarConfigContext,
  WbSidebarItemWithSubmenuContext,
  SubmenuConfig,
} from './config';
import { useWbSidebarOpenState } from './WbSidebarOpenStateContext';

const useStyles = makeStyles<
  Theme,
  { submenuConfig: SubmenuConfig; left: number }
>(
  theme => ({
    drawer: props => ({
      position: 'fixed',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      top: 0,
      bottom: 0,
      padding: 0,
      overflowX: 'hidden',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      cursor: 'default',
      width: props.submenuConfig.drawerWidthClosed,
      transitionDelay: `${props.submenuConfig.defaultOpenDelayMs}ms`,
      '& > *': {
        flexShrink: 0,
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
    drawerOpen: props => ({
      width: props.submenuConfig.drawerWidthOpen,
      boxShadow: theme.shadows[23],
      borderLeft: `1px solid ${theme.palette.bkg.secondary}`,
      borderRight: `1px solid ${theme.palette.bkg.secondary}`,
    }),
    drawerContent: props => ({
      width: props.submenuConfig.drawerWidthOpen,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      height: '100%',
      backgroundColor: theme.palette.grey[50],
    }),
    title: {
      color: theme.palette.secondary.light,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      padding: theme.spacing(2),
    },
  }),
  { name: 'WitboostSidebarSubmenu' },
);

export type WbSidebarSubmenuProps = {
  title?: string;
  icon?: IconComponent;
  children: ReactNode;
};

/**
 * Used inside SidebarItem to display an expandable Submenu
 *
 * @public
 */
export const WbSidebarSubmenu = (props: WbSidebarSubmenuProps) => {
  const { isOpen } = useWbSidebarOpenState();
  const { sidebarConfig, submenuConfig } = useContext(WbSidebarConfigContext);
  const { isHoveredOn: isSubmenuOpen, level } = useContext(
    WbSidebarItemWithSubmenuContext,
  );
  // the current nesting level of the submenu (0 is the first submenu)
  const currLevel = level || 0;
  // compute the margin this submenu needs to be shifted on the left
  // if the parent is the main drawer shift it by the main drawer width, if it's a submenu itself by the submenu width
  const leftConfig = currLevel > 0 ? submenuConfig : sidebarConfig;
  const left = isOpen
    ? leftConfig.drawerWidthOpen
    : leftConfig.drawerWidthClosed;

  const classes = useStyles({ left, submenuConfig });

  const Icon = props.icon;

  return (
    <Box
      // shift the submenu by the parent width - 1 (or 2 if the parent is a submenu itself) to cover the parent borders,
      // thus preventing them to be hovered and accidentally close this submenu
      style={{ marginLeft: left - (currLevel > 0 ? 2 : 1) }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isSubmenuOpen,
      })}
    >
      <Box className={classes.drawerContent}>
        <Typography variant="body1" className={classes.title}>
          {Icon && <Icon />}
          {props.title}
        </Typography>
        {props.children}
      </Box>
    </Box>
  );
};
