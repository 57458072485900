import { WbAutocomplete } from '@agilelab/plugin-wb-platform';
import {
  Entity,
  EntityRelation,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import { FormControl, FormHelperText } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { deserializeEntityRef } from '../../../api';
import {
  WmCompleteUiSchema,
  WmFieldExtensionComponentProps,
} from '../../../extensions/types';
import { usePrevious } from '../../hooks/useEventStream';
import {
  generateURNByKind,
  getEntityDisplayName,
  transformUrnToWitboostId,
} from '@agilelab/plugin-wb-builder-common';
import { extractCustomProperties, isHidden } from '../../utils';
import { fromZodError } from 'zod-validation-error';
import {
  EntityRelationsPickerOptions,
  EntityRelationsPickerSchemaZod,
} from './types';
import { JSONSchema7 } from 'json-schema';
import { get } from 'lodash';

export function parseOptions(
  uiSchema: WmCompleteUiSchema<any>,
  schema: JSONSchema7,
): Error | EntityRelationsPickerOptions {
  const parsedPickerConfig = EntityRelationsPickerSchemaZod.safeParse({
    schema,
    uiSchema,
  });
  if (!parsedPickerConfig.success) {
    const errorMessage = `This field has been disabled due to a misconfiguration error. ${
      fromZodError(parsedPickerConfig.error).message
    }. Please contact the platform team to address this issue.`;
    return new Error(errorMessage);
  }

  const data = parsedPickerConfig.data;

  let filters: ((entity: Entity) => boolean)[] = [];
  if (data.uiSchema['ui:componentsFilter']) {
    filters.push(
      (entity: Entity) =>
        entity.spec?.type === data.uiSchema['ui:componentsFilter'],
    );
  }
  if (data.uiSchema['ui:filters'] && data.uiSchema['ui:filters'].length > 0) {
    filters = filters.concat(
      data.uiSchema['ui:filters'].map(f => {
        return (entity: Entity) => get(entity, f.key) === f.value;
      }),
    );
  }

  return {
    field: {
      name: data.uiSchema['ui:fieldName'],
      path: data.uiSchema['ui:property'],
    },
    returnFormat: data.schema.returnFormat ?? 'urn',
    relation: data.schema.relation ?? 'hasPart',
    filters: filters,
  };
}

async function fetchEntity(
  formContext: any,
  options: EntityRelationsPickerOptions,
  catalogApi: CatalogApi,
): Promise<Entity | undefined> {
  const inputEntityRef = formContext[options.field.name];
  const entityRef = inputEntityRef
    ? deserializeEntityRef(inputEntityRef)
    : undefined;

  if (entityRef) {
    const entity = await catalogApi.getEntityByRef(entityRef);

    if (options.field.path) {
      const subFieldEntityProperty = get(entity, options.field.path);

      const subFieldEntityRef = subFieldEntityProperty
        ? deserializeEntityRef(subFieldEntityProperty)
        : undefined;

      if (subFieldEntityRef) {
        return await catalogApi.getEntityByRef(subFieldEntityRef);
      }
      return undefined;
    }

    return entity;
  }
  return undefined;
}

export async function fetchEntityRelations(
  formContext: any,
  options: EntityRelationsPickerOptions,
  catalogApi: CatalogApi,
): Promise<Entity[] | undefined> {
  const entity = await fetchEntity(formContext, options, catalogApi);

  if (entity) {
    const relationsRefs =
      entity.relations
        ?.filter(
          (relation: EntityRelation) => relation.type === options.relation,
        )
        .map((relation: EntityRelation) => relation.targetRef) ?? [];

    let relatedEntities: Entity[] = (
      await catalogApi.getEntitiesByRefs({ entityRefs: relationsRefs })
    ).items.filter((ent): ent is Entity => !!ent);

    if (options.filters.length > 0) {
      const filterEntity = (e: Entity): boolean => {
        return options.filters.every(filter => filter(e));
      };
      relatedEntities = relatedEntities.filter(filterEntity);
    }

    return relatedEntities;
  }

  return undefined;
}

function formatReturn(
  entity: Entity,
  options: EntityRelationsPickerOptions,
): string {
  switch (options.returnFormat) {
    case 'urn': {
      return generateURNByKind(entity.metadata.name, entity.kind);
    }
    default: {
      return stringifyEntityRef(entity);
    }
  }
}

export const EntityRelationsPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema,
    required,
    uiSchema,
    rawErrors,
    formData,
    idSchema,
    formContext,
  } = props;
  const catalogApi = useApi(catalogApiRef);
  const prevFormContext = usePrevious(formContext);
  const [value, setValue] = useState<Entity | null>(null);
  const [pickerError, setPickerError] = useState<Error | undefined>(undefined);
  const customProps = extractCustomProperties(uiSchema);

  const options = parseOptions(uiSchema, schema);

  useEffect(() => {
    async function setInitialValue() {
      const formDataEntityRef = formData
        ? transformUrnToWitboostId(formData)
        : null;
      const formDataEntity: Entity | null = formDataEntityRef
        ? (await catalogApi.getEntityByRef(formDataEntityRef)) ?? null
        : null;
      setValue(formDataEntity);
    }

    setInitialValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { value: entities, loading } = useAsync(async () => {
    if (options instanceof Error) {
      setPickerError(options);
      return undefined;
    }
    setPickerError(undefined);

    // check if the value of the dependent field name has changed
    const fieldChanged =
      prevFormContext &&
      formContext[options.field.name] !== prevFormContext[options.field.name];

    if (fieldChanged) {
      setValue(null);
    }

    const fetchedEntities = await fetchEntityRelations(
      formContext,
      options,
      catalogApi,
    );

    return fetchedEntities;
  }, [formContext]);

  const onSelect = useCallback(
    (_: any, val: Entity | string | null) => {
      if (typeof val !== 'string' && !(options instanceof Error)) {
        const ref = val ? formatReturn(val, options) : null;
        setValue(val);
        onChange(ref);
      }
    },
    [onChange, options],
  );

  return (
    <FormControl
      margin="normal"
      required={required}
      style={{
        display: isHidden(uiSchema) ? 'none' : undefined,
        height: '10%',
      }}
      error={(rawErrors?.length > 0 && !formData) || pickerError !== undefined}
    >
      <WbAutocomplete
        id={idSchema?.$id}
        value={value || null}
        loading={loading}
        onChange={onSelect}
        autoSelect
        options={entities || []}
        freeSolo={false}
        getOptionLabel={(option: Entity) => getEntityDisplayName(option)}
        getOptionSelected={(option: Entity, val: Entity) =>
          generateURNByKind(option.metadata.name, option.kind) ===
          generateURNByKind(val.metadata.name, val.kind)
        }
        label={schema.title}
        disabled={pickerError}
        helperText={schema.description}
        required={required}
        {...customProps}
      />
      {pickerError && (
        <FormHelperText id="component-error-text">
          {pickerError.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};
