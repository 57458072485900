import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'wb-platform-settings',
});

export const practiceShaperRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-practice-shaper',
  parent: rootRouteRef,
  path: '/practice-shaper',
});

export const importPracticeShaperRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-practice-shaper-import',
  parent: rootRouteRef,
  path: '/practice-shaper-import',
});
