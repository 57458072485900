import {
  COMPONENT,
  DATA_PRODUCT,
  DMB,
  DOMAIN,
  GROUP,
  RELEASE,
  RESOURCE,
  URN as CONST_URN,
  USER,
} from '@agilelab/plugin-wb-platform-common';
import { UrlReader } from '@backstage/backend-common';
import { NotFoundError } from '@backstage/errors';
import yaml from 'yaml';
import {
  ComponentURN,
  DomainURN,
  DPURN,
  GroupURN,
  ReleaseEntity,
  ReleaseURN,
  ResourceURN,
  URN,
  UserURN,
} from '../types';
import { parseEntityRef } from '@backstage/catalog-model';

/**
 * Parses a descriptor, formatted as yaml file, from a given URL
 * @param url
 * @param reader
 * @returns
 */
export async function parseYamlFromUrlIntoObject(
  url: URL | string,
  reader: UrlReader,
): Promise<any> {
  const content = await reader.readUrl(url.toString());

  if (!content) {
    throw new NotFoundError(
      `Could not fetch descriptor from given URL: ${url.toString()}`,
    );
  }

  const buffer = await content.buffer();
  return yaml.parse(buffer.toString());
}

export function deconstructVersion(version: string): {
  major: number;
  minor: number | undefined;
  patch: number | undefined;
  isSnapshot: boolean;
  sequentialId: number | undefined;
} {
  try {
    const groups = RegExp(
      /^(\d+)(\*|.\d+)?(\*|.\d+)?(-SNAPSHOT)?.?(\*|\d+)?/,
    ).exec(version);

    return {
      major: Number(groups![1]),
      minor: groups![2] ? Number(groups![2].replace('.', '')) : undefined,
      patch: groups![3] ? Number(groups![3].replace('.', '')) : undefined,
      isSnapshot: !!groups![4],
      sequentialId: groups![5] ? Number(groups![5]) : undefined,
    };
  } catch (error) {
    throw new Error(
      `unable to parse version ${version}. The version should be in this format x.y[.z][-SNAPSHOT][-w], ${error}`,
    );
  }
}

/**
 * in releases/snapshots, the entity version is persisted without the patch number.
 * With this method, we force the patch number to send it to the coordinator that expects it.
 * @param options : pass either `v` or `release` to fetch the version from
 */
export function buildReleaseVersion(options: {
  release?: ReleaseEntity;
  v?: string;
}) {
  let version;
  const passedVersion = options.v ?? options.release?.metadata.version;
  if (!passedVersion)
    throw new Error(
      'You must pass either the release or the version as string',
    );
  const deconstructuredVersion = deconstructVersion(passedVersion);
  version = `${deconstructuredVersion.major}.${
    deconstructuredVersion.minor ?? 0
  }.${deconstructuredVersion.patch ?? 0}`;
  if (deconstructuredVersion.sequentialId)
    version += `-SNAPSHOT-${deconstructuredVersion.sequentialId}`;
  return version;
}

export function generateURNByKind(entityName: string, kind: string): string {
  const urnKind = encodeKindForURN(kind);
  return [
    CONST_URN,
    DMB,
    urnKind,
    generateURNNameForEntity(entityName, kind),
  ].join(':');
}

export const entityRefToUrn = (ref: string) => {
  const entity = parseEntityRef(ref);
  const res = generateURNByKind(entity.name, entity.kind);

  return res;
};

/**
 * DO NOT MODIFY THIS
 * Generates a URN starting from metadata.name and kind of an entity.
 * In contrast to what RBAC needs, this function performs an hyphen replacement. In fact, RBAC has its own generateURNByKind function.
 * However we cannot align those two generators otherwise any resource having an hyphen in its name would be duplicated in the catalog if we stop to do this replacement.
 * @param name - metadata.name inside the entity
 * @param kind
 * @returns
 */
export function generateURNNameForEntity(name: string, kind: string): string {
  if (kind?.toLocaleLowerCase() === 'domain') {
    return name
      .normalize('NFD')
      .replace(/[-]+/g, '') // we leave hyphen as the only char that we replace just for retrocompatibility
      .toLowerCase();
  }
  return name.replaceAll('.', ':');
}

export function parseUrn(urn: string): Partial<URN> {
  const arr = urn.split(':');

  if (
    arr.length !== 4 &&
    arr.length !== 5 &&
    arr.length !== 6 &&
    arr.length !== 7
  ) {
    throw new Error(`Malformed URN received: '${urn}'`);
  }

  const kind = arr[2].trim().toLowerCase();
  switch (kind) {
    case RELEASE:
      return {
        urn: CONST_URN,
        dmb: DMB,
        kind: arr[2],
        domain: arr[3],
        name: arr[4],
        version: arr[5],
        releaseVersion: arr[6],
      } as ReleaseURN;
    case DATA_PRODUCT:
      return {
        urn: CONST_URN,
        dmb: DMB,
        kind: arr[2],
        domain: arr[3],
        name: arr[4],
        version: arr[5],
      } as DPURN;
    case COMPONENT:
      return {
        urn: CONST_URN,
        dmb: DMB,
        kind: arr[2],
        domain: arr[3],
        name: arr[4],
        version: arr[5],
        component: arr[6],
      } as ComponentURN;
    case RESOURCE:
      return {
        urn: CONST_URN,
        dmb: DMB,
        kind: arr[2],
        domain: arr[3],
        name: arr[4],
      } as ResourceURN;
    case USER:
      return {
        urn: CONST_URN,
        dmb: DMB,
        kind: arr[2],
        name: arr[3],
      } as UserURN;
    case GROUP:
      return {
        urn: CONST_URN,
        dmb: DMB,
        kind: arr[2],
        name: arr[3],
      } as GroupURN;
    case DOMAIN:
      return {
        urn: CONST_URN,
        dmb: DMB,
        kind: arr[2],
        domain: arr[3],
      } as DomainURN;

    default:
      throw Error(`Kind not recognized for URN '${urn}'`);
  }
}

export function encodeKindForURN(kind: string): string {
  if (kind?.trim() === '') {
    throw Error('Cannot encode kind to URN. Empty kind supplied');
  }

  const lowerCaseKind = kind?.trim()?.toLowerCase();
  switch (lowerCaseKind) {
    case 'system':
      return DATA_PRODUCT;
    case 'component':
      return COMPONENT;
    case 'resource':
      return RESOURCE;
    case 'domain':
      return DOMAIN;
    default:
      return lowerCaseKind;
  }
}

/**
 * Parses a given urn to the ID that is more familiar with the builder e.g. component:marketing.asdf.0.impala-cdp-output-port
 * @param urn
 * @returns
 */
export const transformUrnToWitboostId = (urn: string) => {
  try {
    const entityUrn = parseUrn(urn);
    const entityKind = entityUrn?.kind?.trim().toLowerCase();
    if (entityUrn)
      switch (entityKind) {
        case COMPONENT: {
          const componentUrn = entityUrn as ComponentURN;
          return `component:${componentUrn.domain}.${componentUrn.name}.${componentUrn.version}.${componentUrn.component}`;
        }
        case DOMAIN: {
          const domainUrn = entityUrn as DomainURN;
          return `domain:${domainUrn.domain}`;
        }
        case USER: {
          const userUrn = entityUrn as UserURN;
          return `user:${userUrn.name}`;
        }
        case GROUP: {
          const groupUrn = entityUrn as GroupURN;
          return `group:${groupUrn.name}`;
        }
        case DATA_PRODUCT: {
          const dpUrn = entityUrn as DPURN;
          return `system:${dpUrn.domain}.${dpUrn.name}.${dpUrn.version}`;
        }
        case RESOURCE: {
          const rsrUrn = entityUrn as ResourceURN;
          return `resource:${rsrUrn.domain}.${rsrUrn.name}`;
        }
        case RELEASE: {
          const releaseUrn = entityUrn as ReleaseURN;
          return `release:${releaseUrn.domain}.${releaseUrn.name}.${releaseUrn.version}.${releaseUrn.releaseVersion}`;
        }
        default:
          return undefined;
      }
  } catch (e) {
    return undefined;
  }
  return undefined;
};
