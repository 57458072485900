import {
  BackstageUserIdentity,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { GUEST_USER_REF } from '@agilelab/plugin-wb-platform-common';

export type UserEntity = {
  username: string;
  displayName: string;
  loading: boolean;
  error: Error | undefined;
};

/**
 * Hook to access the username and display-name of the current user entity.
 *
 * This hook fetches the user entity from the catalog API using the user's
 * Backstage identity reference.
 * It returns a `UserEntity` object containing
 * the loading state, any error encountered, the username, and the display name
 * of the user.
 *
 * @returns An object of type `UserEntity` containing:
 * - `loading`: A boolean indicating if the user entity is still being fetched.
 * - `error`: An error object if there was an error fetching the user entity.
 * - `username`: A string representing the username of the user entity, or `undefined`
 *   if there was an error or the information is still loading.
 * - `displayName`: A string representing the display name of the user entity, or an
 *   empty string if not available.
 *
 * @example
 * ```tsx
 * const { loading, error, username, displayName } = useUsername();
 * ```
 */
export function useUsername(): UserEntity {
  const catalogApi = useApi(catalogApiRef);

  const { backstageIdentity } = useFetchUserInfo();

  const userEntityRequest = useAsync(async () => {
    if (backstageIdentity) {
      return await catalogApi.getEntityByRef(backstageIdentity.userEntityRef);
    }
    return undefined;
  }, [backstageIdentity]);

  return {
    loading: userEntityRequest.loading,
    error: userEntityRequest.error,
    username: userEntityRequest.value
      ? humanizeEntityRef(userEntityRequest.value)
      : undefined,
    displayName: userEntityRequest.value
      ? (userEntityRequest.value.spec?.profile as any)?.displayName ?? ''
      : undefined,
  } as UserEntity;
}

/**
 * Custom hook to fetch the current user's Backstage identity information.
 *
 * This hook uses the identity API to retrieve the user's Backstage identity.
 * It returns an object containing the `backstageIdentity`, which is either
 * the user's identity information or `undefined` if the information is still
 * loading, or there was an error.
 *
 * @returns An object containing:
 * - `backstageIdentity`: The current user's Backstage identity, or `undefined` if
 *   the information is still loading or there was an error.
 *
 * @example
 * ```tsx
 * const { backstageIdentity } = useFetchUserInfo();
 * ```
 */
export function useFetchUserInfo(): {
  backstageIdentity: BackstageUserIdentity | undefined;
} {
  const identityApi = useApi(identityApiRef);

  const identityResult = useAsync(async () => {
    return {
      backstageIdentity: await identityApi.getBackstageIdentity(),
    };
  }, [identityApi]);

  if (!identityResult.value || identityResult.loading || identityResult.error) {
    return {
      backstageIdentity: undefined,
    };
  }

  return identityResult.value;
}

/**
 * Custom hook to fetch the logged-in user's username.
 *
 * This hook uses the catalog API to retrieve the entity associated with the
 * current user's identity reference and returns a human-readable username.
 * If the user information is still loading, there is an error, or the user
 * is not logged in, it returns `undefined`.
 *
 * @returns A string representing the logged-in user's username, or `undefined`
 * if the user information is still loading, there is an error, or the user is not logged in.
 *
 * @example
 * ```tsx
 * const username = useLoggedUsername();
 * ```
 */
export function useLoggedUsername(): string | undefined {
  const catalogApi = useApi(catalogApiRef);
  const { backstageIdentity } = useFetchUserInfo();

  const { value, error, loading } = useAsync(async () => {
    if (backstageIdentity) {
      return await catalogApi.getEntityByRef(backstageIdentity.userEntityRef);
    }

    return undefined;
  }, [backstageIdentity]);

  if (!value || error || loading) {
    return undefined;
  }

  return humanizeEntityRef(value);
}

/**
 * Custom hook to determine if the current user is a guest user.
 *
 * This hook fetches the user information and checks whether the user identity
 * reference matches a guest user reference.
 * It returns an object indicating the loading state and whether the user is a guest.
 *
 * @returns An object containing:
 * - `loading`: A boolean indicating if the user information is still being fetched.
 * - `data`: An optional boolean that is `true` if the user is a guest, and `false` otherwise.
 *   It is `undefined` while the user information is still loading.
 *
 * @example
 * ```tsx
 * const { loading, data: isNotGuest } = useIsGuestUser();
 * ```
 */
export function useIsGuestUser(): { loading: boolean; data?: boolean } {
  const { backstageIdentity } = useFetchUserInfo();
  if (!backstageIdentity) {
    return { loading: true };
  }
  return {
    loading: false,
    data: backstageIdentity.userEntityRef === GUEST_USER_REF,
  };
}

/**
 * Transforms an entity reference string into a Backstage `EntityName` type.
 *
 * The `entityRef` string must follow the structure `<kind>:<namespace>/<name>`.
 * If the `entityRef` does not match this structure, the default values are
 * `kind: 'user'`, `namespace: 'default'`, and `name` set to the given `entityRef`.
 *
 * @param entityRef - The entity reference string to be transformed.
 * @returns An object of type `EntityName` containing:
 * - `kind`: The kind of the entity.
 * - `namespace`: The namespace of the entity.
 * - `name`: The name of the entity.
 *
 * @example
 * ```ts
 * const entityName = getEntityNameByRef('component:default/my-service');
 * // Returns { kind: 'component', namespace: 'default', name: 'my-service' }
 * ```
 */
export function getEntityNameByRef(entityRef: string) {
  const entityRefCheckerRegex = /[a-zA-Z]+:[a-zA-Z]+\/[a-zA-Z._0-9]+$/;

  if (!entityRefCheckerRegex.test(entityRef))
    return { kind: 'user', namespace: 'default', name: entityRef };

  const entityRefRegex = /:|\//;

  const result = entityRef.split(entityRefRegex);

  return { kind: result[0], namespace: result[1], name: result[2] };
}
