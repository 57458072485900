import { Box } from '@material-ui/core';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const WbCardContent: React.FC<Props> = ({ children, style }) => {
  return <Box style={{ padding: '12px 16px', ...style }}>{children}</Box>;
};
