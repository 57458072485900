import { AppTheme } from '@backstage/core-plugin-api';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import React, { useMemo } from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  createWitboostTheme,
  witboostComponents,
  witboostPageTheme,
  witboostPalette,
  witboostTypography,
} from './LightAgileTheme';
import { merge } from 'lodash';
import { UnifiedTheme, UnifiedThemeProvider } from '@backstage/theme';
import { ThemeOptions as ThemeOptionsV5 } from '@mui/material/styles';

const getPalette = async (): Promise<PaletteOptions | null> => {
  try {
    const res = await fetch('/custom_style/customPalette.json');
    const palette = await res.json();
    return palette;
  } catch (_error) {
    return null;
  }
};

const getOverrides = async (): Promise<
  ThemeOptionsV5['components'] | undefined
> => {
  try {
    const res = await fetch('/custom_style/customOverrides.json');
    const palette = await res.json();
    return palette;
  } catch (_error) {
    return undefined;
  }
};

export function useCustomTheme() {
  const {
    value: palette,
    loading: loadingPalette,
    error: errorPalette,
  } = useAsync(async () => {
    return await getPalette();
  });

  const {
    value: overrides,
    loading: loadingOverrides,
    error: errorOverrides,
  } = useAsync(async () => {
    return await getOverrides();
  });

  const customBasicTheme: UnifiedTheme | null = useMemo(() => {
    if (palette || overrides) {
      const customPalette = merge(witboostPalette, palette);
      const customOverrides = merge(overrides, witboostComponents);
      return createWitboostTheme({
        palette: customPalette,
        typography: witboostTypography,
        pageTheme: witboostPageTheme,
        defaultPageTheme: 'home',
        components: customOverrides,
      });
    }
    return null;
  }, [palette, overrides]);

  return {
    error: errorPalette || errorOverrides,
    loading: loadingPalette || loadingOverrides,
    theme: customBasicTheme
      ? ({
          id: 'custom-theme-light',
          title: 'Custom Light',
          variant: 'light',
          Provider: ({ children }) => {
            return (
              <UnifiedThemeProvider
                theme={customBasicTheme}
                children={children}
              />
            );
          },
        } as Partial<AppTheme> & Omit<AppTheme, 'theme'>)
      : null,
  };
}
