import {
  DomainEntity,
  DomainResolver,
  SEARCH_FOR_PARENT_DOMAIN,
} from '@agilelab/plugin-wb-marketplace-common';
import { ApolloClient, gql } from '@apollo/client';

/**
 * Extract the whole hierarchy of a given domain and a parent domain from the Marketplace
 * database.
 * @param domains: a list containing the starting domain name
 * @param apolloClient: used to query the Marketplace database
 * @param parentDomain: the parent domain
 * @returns
 */
export async function resolveDomainRelations(
  resultingDomains: DomainEntity[],
  apolloClient: ApolloClient<object>,
): Promise<string[]> {
  if (!resultingDomains.length) {
    return ['Unassigned'];
  }

  const result = await apolloClient.query<DomainResolver>({
    query: gql(SEARCH_FOR_PARENT_DOMAIN),
    variables: {
      sourceInstanceExternalId: resultingDomains.at(0)?.data.external_id,
    },
  });

  if (!result.data.domainData.length) {
    return resultingDomains.map(d => d.data.name);
  }

  return await resolveDomainRelations(
    [result.data.domainData[0], ...resultingDomains],
    apolloClient,
  );
}
