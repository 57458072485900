import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  GetTaxonomiesResponse,
  PracticeShaperApi,
} from '@agilelab/plugin-wb-practice-shaper-common';
import { useApi } from '@backstage/core-plugin-api';
import { practiceShaperApiRef } from '@agilelab/plugin-wb-practice-shaper';
import { useQuery } from '@tanstack/react-query';
import { stringifyEntityRef } from '@backstage/catalog-model';

type TaxonomyItem = GetTaxonomiesResponse['items'][number];

type Taxonomy = {
  selectedTaxonomyRef: string;
  selectableTaxonomies: TaxonomyItem[];
  setSelectedTaxonomyRef: (val: string) => void;
};

export const DEFAULT_TAXONOMY_KEY = 'category';
export const ALL_TAXONOMIES_FILTER = 'all';

type Options = {
  queryParamKey?: string;
  localStorageKey?: string;
};

const fetchData = async (api: PracticeShaperApi) => {
  return await api.getTaxonomies({});
};

export const useTaxonomySelection = (options?: Options): Taxonomy => {
  const [queryParamKey, localStorageKey] = [
    options?.queryParamKey || DEFAULT_TAXONOMY_KEY,
    options?.localStorageKey || DEFAULT_TAXONOMY_KEY,
  ];

  const api = useApi(practiceShaperApiRef);

  const { data } = useQuery({
    queryKey: ['taxonomies'],
    queryFn: async () => {
      return fetchData(api);
    },
  });

  const taxonomies = useMemo(() => {
    return data?.items || [];
  }, [data]);

  const validTaxonomies = useMemo(
    () => taxonomies.filter(t => t.spec.enabled),
    [taxonomies],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const selected = useMemo(
    () => searchParams.get(queryParamKey),
    [searchParams, queryParamKey],
  );

  // sync the local storage with the query params when they change
  useEffect(() => {
    if (selected !== null) localStorage.setItem(localStorageKey, selected);
  }, [selected, localStorageKey]);

  // setter that sets the new taxonomy query param value by keeping the other query params unaltered
  const setSelectedTaxonomyRef = useCallback(
    (value: string) => {
      // get currents query params in the url as a js object
      const currentParams = {
        ...Object.fromEntries(new URLSearchParams(window.location.search)),
      };
      setSearchParams(
        // change only the taxonomy query param from the current params
        { ...currentParams, [queryParamKey]: value },
        // replace = true so it doesn't add a new navigation entry to the stack
        { replace: true },
      );
    },
    [setSearchParams, queryParamKey],
  );

  useEffect(() => {
    // if there is no value in query param but there is in local storage, set the local storage value as query param
    const localValue = localStorage.getItem(localStorageKey);
    if (selected === null && localValue !== null) {
      setSelectedTaxonomyRef(localValue);
      return;
    }

    // if there is only one available taxonomy and query param is not in the url or "all taxonomies" is selected, select that single taxonomy instead
    if (
      (selected === null || selected === ALL_TAXONOMIES_FILTER) &&
      validTaxonomies.length === 1
    ) {
      setSelectedTaxonomyRef(stringifyEntityRef(validTaxonomies[0]));
    }
  }, [selected, validTaxonomies, setSelectedTaxonomyRef, localStorageKey]);

  return {
    selectableTaxonomies: validTaxonomies,
    selectedTaxonomyRef:
      selected ||
      localStorage.getItem(localStorageKey) ||
      ALL_TAXONOMIES_FILTER,
    setSelectedTaxonomyRef,
  };
};
