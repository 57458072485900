import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import React from 'react';
import { TableCellProps, TableContent } from './contents/TableContent';
import { TableHeader } from './contents/TableHeader';
import { TableContentLoader } from './loaders/TableContentLoader';
import { WbNoData } from '../WbNoData';

export interface WbTableLoader {
  loader?: JSX.Element;
  loading: boolean;
  columnCount?: number;
  rowCount?: number;
}
export interface WbTableComponents<T> {
  tableHeader?: JSX.Element;
  tableLoader?: WbTableLoader;
  tableContent?: {
    body?: JSX.Element;
    columns?: Array<TableCellProps<T>>;
    rows?: Array<T>;
    onRowClick?: (
      element: T,
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ) => void;
    selectable?: boolean;
  };
}

export interface WbTableStyles {
  container?: React.CSSProperties;
  header?: React.CSSProperties;
  body?: React.CSSProperties;
}
export type WbTableSortDirection = 'asc' | 'desc';
export type WbTableSort<T> = (
  event: React.MouseEvent<HTMLSpanElement>,
  cell: TableCellProps<T>,
  direction: WbTableSortDirection,
) => void;
export interface WbTableProps<T> {
  components?: WbTableComponents<T>;
  noDataLabel?: string;
  noDataComponent?: JSX.Element;
  pagination?: {
    rowsPerPageOptions?: Array<number>;
    count: number;
    limit: number;
    offset: number;
    onPageChange: (page: number) => void;
    onRowsPerPageChange: (rowsPerPage: number) => void;
  };
  styles?: WbTableStyles;
  classNames?: {
    body?: string;
  };
  onSort?: WbTableSort<T>;
  stickyHeader?: boolean;
}

function TableLoader({ loader, columnCount, rowCount = 10 }: WbTableLoader) {
  if (loader) {
    return loader;
  }
  if (columnCount) {
    return <TableContentLoader rows={rowCount} tableCells={columnCount} />;
  }
  return <></>;
}

function WbTableHeader<T>({
  components,
  styles,
  onSort,
}: Omit<WbTableProps<T>, 'classNames' | 'pagination'>) {
  if (components?.tableHeader) {
    return components.tableHeader;
  }
  if (components?.tableContent?.columns) {
    return (
      <TableHeader
        onSort={onSort}
        columns={components.tableContent.columns}
        style={{ ...styles?.header }}
      />
    );
  }
  return <></>;
}

function WbTableContent<T>({
  components,
  styles,
  classNames,
}: WbTableProps<T>) {
  if (components?.tableContent?.body) {
    return components.tableContent.body;
  }
  if (
    !!components?.tableContent?.columns &&
    !!components?.tableContent?.rows &&
    components?.tableContent?.rows?.length
  ) {
    return (
      <TableContent<T>
        columns={components.tableContent.columns}
        rows={components.tableContent.rows}
        onRowClick={components.tableContent.onRowClick}
        rowStyle={styles?.body}
        rowClassName={classNames?.body}
        selectable={components.tableContent.selectable}
      />
    );
  }
  return <></>;
}

export function WbTable<T>({
  components,
  noDataLabel,
  noDataComponent,
  pagination,
  styles,
  classNames,
  onSort,
  stickyHeader,
}: WbTableProps<T>) {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    pagination?.onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    pagination?.onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  if (
    !components?.tableLoader?.loading &&
    !components?.tableContent?.body &&
    !components?.tableContent?.rows?.length
  )
    return noDataComponent || <WbNoData text={noDataLabel} />;
  return (
    <>
      <TableContainer style={styles?.container}>
        <Table stickyHeader={stickyHeader} size="small">
          <WbTableHeader
            components={components}
            onSort={onSort}
            styles={styles}
          />
          {components?.tableLoader?.loading ? (
            <TableBody>
              <TableLoader
                columnCount={components.tableContent?.columns?.length}
                {...components?.tableLoader}
              />
            </TableBody>
          ) : (
            <WbTableContent
              components={components}
              styles={styles}
              classNames={classNames}
            />
          )}
        </Table>
      </TableContainer>

      {!!pagination?.count && (
        <TablePagination
          rowsPerPageOptions={
            pagination.rowsPerPageOptions || [25, 50, 75, 100, 200]
          }
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.limit}
          page={pagination.offset / pagination.limit}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ alignSelf: 'flex-end' }}
        />
      )}
    </>
  );
}
