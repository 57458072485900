import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import {
  WmFieldExtensionComponentProps,
  WmUiSchema,
} from '../../../extensions/types';
import {
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import _, { isEqual } from 'lodash';
import { Entity } from '@backstage/catalog-model';
import useAsync from 'react-use/lib/useAsync';
import {
  isEntity,
  renderMessageWithSeverity,
  WbTextField,
} from '@agilelab/plugin-wb-platform';
import { extractCustomProperties, isHidden } from '../../utils';
import {
  EntitySelectionPickerSchema,
  EntitySelectionPickerSchemaZod,
} from './types';
import { fromZodError } from 'zod-validation-error';
import { useTheme } from '@material-ui/core';
import {
  buildResolvedObject,
  extractArrayLabel,
  resolveEntities,
} from './EntitySelectionPicker.utils';
import { getEntityDisplayName } from '@agilelab/plugin-wb-builder-common';
import { JSONSchema7 } from 'json-schema';
import { useFormExtraConfigContext } from '../../../contexts/FormExtraConfigContext';

export const allowArbitraryValues = (
  uiSchema: WmUiSchema,
  schema: JSONSchema7,
): boolean => {
  const configValue =
    (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? false;

  // even if set to true in the config, this works only if the type of the schema is string
  return configValue && schema.type === 'string';
};

function convertFieldNameValueIntoArray(
  fieldNameValue: any,
): (Record<string, any> | string)[] {
  if (!Array.isArray(fieldNameValue)) {
    return [fieldNameValue];
  }
  return fieldNameValue;
}

async function resolveEntitiesAndExtractLabels(
  fieldValue: string | Entity | undefined,
  catalogApi: CatalogApi,
  identityApi: IdentityApi,
  getPropertyValues: (
    obj: Record<string, any>,
  ) => Record<string, any> | string | undefined,
  extractLabel: (propertyValue: any[]) => string,
): Promise<
  | {
      propertyRefs: (Record<string, any> | string | undefined)[];
      propertyLabel: string;
    }
  | undefined
> {
  if (fieldValue) {
    const token = (await identityApi.getCredentials()).token;
    const entities = await resolveEntities(
      convertFieldNameValueIntoArray(fieldValue),
      { catalogApi, token },
    );

    if (entities.length) {
      const propertyValues = entities.map(getPropertyValues);
      return {
        propertyRefs: propertyValues,
        propertyLabel: extractLabel(propertyValues),
      };
    }
  }

  return undefined;
}

export const EntitySelectionPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema,
    required,
    uiSchema,
    rawErrors,
    formData,
    formContext,
  } = props;
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const theme = useTheme();
  const [pickerConfig, setPickerConfig] = useState<
    EntitySelectionPickerSchema | undefined
  >(undefined);
  const [pickerMessages, setPickerMessages] = useState<
    Map<string, { line: string; severity: 'info' | 'warning' | 'error' }>
  >(new Map([]));
  const customProps = extractCustomProperties(uiSchema);

  const fieldValue = useMemo(() => {
    return pickerConfig
      ? (_.get(formContext, pickerConfig['ui:fieldName']) as string | Entity)
      : undefined;
  }, [pickerConfig, formContext]);

  const manualEditEnabled = allowArbitraryValues(uiSchema, schema);

  // if true, whenever the selected referenced entity changes value, the value of this picker should change as well (default behaviour)
  // when set to false, the user is editing the value of this field manually and it shouldn't be overridden when changing the referenced entity
  const [connectedToReferencedEntity, setConnectedToReferencedEntity] =
    useState(() => {
      /**
       * if the schema isn't of type string or the formData was not defined at first render (or was defined as the schema default), starts at true (default behaviour)
       * if formData was already defined at first render, it means that
       * the value may have been set manually in the create template and therefore must not be overridden
       * with the referenced entity value, so this starts at false
       */
      return (
        schema.type !== 'string' ||
        formData === undefined ||
        formData === schema.default
      );
    });

  const isUiPropertySet = (): boolean =>
    !!(fieldValue && pickerConfig && pickerConfig['ui:property']);
  const isMapToPropertySet = (): boolean =>
    !!(fieldValue && pickerConfig && pickerConfig.mapTo);

  const getPropertyValues = (obj: Record<string, any>): Record<string, any> => {
    if (isUiPropertySet()) {
      return _.get(obj, pickerConfig!['ui:property']!) ?? schema.default;
    }

    if (isMapToPropertySet()) {
      return buildResolvedObject(
        obj,
        pickerConfig!.mapTo! as Record<string, any> | '.',
      );
    }

    return obj;
  };

  const resolvePickerValue = useCallback(
    (propertyRefs: (Record<string, any> | string | undefined)[]): any => {
      if (propertyRefs.length === 1 && schema.type !== 'array') {
        return propertyRefs[0];
      }

      return propertyRefs;
    },
    [schema],
  );

  const resolveObject = (
    obj: Record<string, any> | string,
  ): string | undefined => {
    if (typeof obj === 'string') {
      return obj;
    }
    if (pickerConfig?.['ui:displayLabel']) {
      return _.get(obj, pickerConfig!['ui:displayLabel']!);
    }
    if (isEntity(obj)) {
      return getEntityDisplayName(obj as Entity);
    }
    return undefined;
  };

  /**
   * Extract a property from a referenced entity or map the entity
   * to its subFields.
   */
  const { value: loadedProperty } = useAsync(async () => {
    const propertyFetched = await resolveEntitiesAndExtractLabels(
      fieldValue,
      catalogApi,
      identityApi,
      getPropertyValues,
      extractArrayLabel(resolveObject, schema.default as string | undefined),
    );

    return propertyFetched;
  }, [catalogApi, fieldValue, identityApi]);

  const resolvedReferencedValue = useMemo(
    () =>
      loadedProperty
        ? resolvePickerValue(loadedProperty.propertyRefs)
        : undefined,
    [loadedProperty, resolvePickerValue],
  );

  useEffect(() => {
    if (
      connectedToReferencedEntity &&
      // since flushSync is expensive, only change the value when the referenced value is different (by value, not reference) from the current value(formData)
      !isEqual(resolvedReferencedValue, formData)
    ) {
      /* if many EntitySelectionPickers depend on the same Entity, this onChange will be called on each of them almost simultaneously
       * because of the way React and rjsf (https://github.com/rjsf-team/react-jsonschema-form/issues/3367) update the state,
       * many of the onChange calls would be skipped and only some pickers would correctly update
       * flushSync makes each onChange synchronous (aka triggers a rerender immediately) instead of batching them, making it more expensive but allowing every onChange to succeed
       * flushSync is wrapped in queueMicrotask because it is not possible to call it directly in useEffect
       * TODO: find a better solution if possible
       */
      queueMicrotask(() => {
        flushSync(() => {
          onChange(resolvedReferencedValue);
        });
      });
    }
  }, [
    resolvedReferencedValue,
    formData,
    onChange,
    connectedToReferencedEntity,
  ]);

  // this matters when the manualEditMode is enabled
  // if the value input by the user (or the starting value in case it's an edit template with already filled values) matches the referenced entity values, connect them again
  useEffect(() => {
    if (resolvedReferencedValue === formData) {
      setConnectedToReferencedEntity(true);
    }
  }, [resolvedReferencedValue, formData]);

  /**
   * Checks if there are misconfiguration while setting up the EntitySelectionPicker
   */
  useEffect(() => {
    const parsedPickerConfig = EntitySelectionPickerSchemaZod.safeParse({
      ...uiSchema,
      ...schema,
    });
    if (!parsedPickerConfig.success) {
      const parsingErrorMessage = `Error while trying to parse EntitySelectionPicker configurations: ${fromZodError(
        parsedPickerConfig.error,
      ).message.toLowerCase()}. Please contact the platform team.`;
      setPickerMessages(existingErrors =>
        existingErrors.set('parsing_error', {
          line: parsingErrorMessage,
          severity: 'error',
        }),
      );
      return;
    }
    setPickerMessages(existingErrors => {
      existingErrors.delete('parsing_error');
      return existingErrors;
    });
    setPickerConfig(parsedPickerConfig.data);
  }, [uiSchema, schema]);

  /**
   * in case the formData value is of type string, show directly that in the input value, since it may have been input manually by the user
   * and be different from the referenced entity value
   */
  const inputValue =
    typeof formData === 'object' ? loadedProperty?.propertyLabel : formData;

  const { debounceMs } = useFormExtraConfigContext();

  return (
    <>
      <WbTextField
        label={schema.title}
        style={{
          display: isHidden(uiSchema) ? 'none' : undefined,
          width: '100%',
        }}
        inputProps={{ style: { width: '100%' } }}
        error={rawErrors?.length > 0 && !formData}
        disabled={!manualEditEnabled}
        helperText={
          pickerMessages.size
            ? renderMessageWithSeverity(
                Array.from(pickerMessages.values()),
                theme,
              )
            : schema.description
        }
        required={required}
        debounceMs={debounceMs}
        onChange={({ target: { value } }) => {
          // mark the value as manually edited so the value won't change anymore if the referenced entity property changes
          setConnectedToReferencedEntity(false);
          onChange(value);
        }}
        value={inputValue ?? ''}
        {...customProps}
      />
    </>
  );
};
