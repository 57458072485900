import { transformUrnToWitboostId } from '@agilelab/plugin-wb-builder-common';
import {
  Pagination,
  PermissionSettingsView,
  PermissionViewFilters,
} from '@agilelab/plugin-wb-rbac-common';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { getEntitiesDisplayNames } from './enrichDetails';

export interface PermissionContextType {
  loading: boolean;
  filters: PermissionViewFilters;
  changeFilters: <K extends keyof PermissionViewFilters>(
    key: K,
    value: PermissionViewFilters[K],
  ) => void;
  pagination: Pagination;
  setPagination: (p: Pagination) => void;
  permissions: PermissionSettingsView[];
  permissionsCount: number;
  reloadTable: () => void;
  dispatchOrder: (order: { field: string; direction: 'asc' | 'desc' }) => void;
}

export const PermissionContext = React.createContext<PermissionContextType>(
  {} as PermissionContextType,
);

type Props = {
  children?: React.ReactNode;
};

export const PermissionContextProvider: React.FC<Props> = ({ children }) => {
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const fetchApi = useApi(fetchApiRef);

  const orderReducer = (
    _state: any,
    action: { field: string; direction: 'asc' | 'desc' },
  ) => {
    switch (action.field) {
      case 'Scope':
        return {
          field: 'entity_ref_displayname',
          direction: action.direction,
        };
      case 'Description':
        return {
          field: 'description',
          direction: action.direction,
        };
      case 'Permission':
        return {
          field: 'display_name',
          direction: action.direction,
        };

      case 'Subject':
        return {
          field: 'subjectdisplayname',
          direction: action.direction,
        };

      case 'unsorted':
        return {};
      default:
        return {};
    }
  };

  const [order, dispatchOrder] = useReducer(orderReducer, {});
  const [loading, setLoading] = useState(true);
  const [permissionsTotal, setPermissionsTotal] = useState<number>(0);

  const [permissions, setPermissions] = React.useState<
    PermissionSettingsView[]
  >([]);

  const [filters, setFilters] = useState<PermissionViewFilters>({
    text: '',
    limit: 25,
  });
  const [pagination, setPagination] = useState<Pagination>({
    limit: filters.limit ?? 25,
    offset: 0,
  });

  useAsync(async () => {
    const identity = await identityApi.getCredentials();

    const response = await fetchApi.fetch(
      `${await discoveryApi.getBaseUrl('rbac')}/permissionandsubjects?offset=${
        pagination.offset
      }&limit=${pagination.limit}&searchKeyword=${filters.text}`,
      {
        headers: identity.token
          ? { authorization: `Bearer ${identity.token}` }
          : {},
      },
    );

    const data = await response.json();

    const enrichedPermissions = data.permissions.map((permission: any) => {
      const [, name] = (
        transformUrnToWitboostId(permission.entity_ref ?? '') ?? ''
      ).split(':');
      return { transformed_entity_ref: name ?? '', ...permission };
    });

    const entitiesFilters: { 'metadata.name': string }[] = (
      [
        ...new Set(
          enrichedPermissions.map(
            (role: PermissionSettingsView) => role.transformed_entity_ref,
          ),
        ),
      ] as string[]
    ).map((entityRef: string) => ({
      'metadata.name': entityRef,
    }));

    // holds a map of entity name and its display name
    const displayNamesMap = await getEntitiesDisplayNames(
      catalogApi,
      entitiesFilters,
      {
        chunkLimit: 50,
      },
    );

    const userFriendlyPermissions = enrichedPermissions.flatMap(
      (permission: PermissionSettingsView) => {
        return displayNamesMap.has(
          permission.transformed_entity_ref?.toLowerCase(),
        )
          ? [
              {
                ...permission,
                entity_ref_displayname:
                  displayNamesMap.get(permission.transformed_entity_ref) ??
                  permission.transformed_entity_ref,
              } as PermissionSettingsView,
            ]
          : [
              {
                ...permission,
                entity_ref_displayname: permission.transformed_entity_ref ?? '',
                warning: permission.transformed_entity_ref !== '',
              } as PermissionSettingsView,
            ];
      },
    );

    setPermissions(userFriendlyPermissions);
    setPermissionsTotal((data.total as number) ?? 0);
    setLoading(false);
  }, [
    catalogApi,
    discoveryApi,
    identityApi,
    pagination.limit,
    pagination.offset,
    filters.text,
  ]);

  const sortedPermissions = useMemo(() => {
    return permissions.sort(
      (a: PermissionSettingsView, b: PermissionSettingsView) => {
        if (order?.field) {
          if (order.direction === 'asc') {
            return a[order.field as keyof PermissionSettingsView] >
              b[order.field as keyof PermissionSettingsView]
              ? 1
              : -1;
          }
          return a[order.field as keyof PermissionSettingsView] <
            b[order.field as keyof PermissionSettingsView]
            ? 1
            : -1;
        }
        return 1;
      },
    );
  }, [permissions, order.field, order.direction]);

  const reloadTable = useCallback(() => {
    setPagination({ ...pagination, offset: 0 });
  }, [setPagination, pagination]);

  const contextValue = useMemo(
    () => ({
      loading,
      filters,
      changeFilters: <K extends keyof PermissionViewFilters>(
        key: K,
        filterValue: PermissionViewFilters[K],
      ) => {
        setPagination(p => ({ ...p, offset: 0 }));
        setFilters(f => ({ ...f, [key]: filterValue }));
      },
      pagination,
      setPagination,
      permissions: sortedPermissions,
      permissionsCount: permissionsTotal,
      reloadTable,
      dispatchOrder,
    }),
    [
      loading,
      filters,
      pagination,
      sortedPermissions,
      permissionsTotal,
      reloadTable,
      dispatchOrder,
    ],
  );

  return (
    <PermissionContext.Provider value={contextValue}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissionContext = () => React.useContext(PermissionContext);
