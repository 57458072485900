import { gql } from '@apollo/client/core';

export const GET_DP_TOTAL = gql`
  query GetDpTotal {
    instance_aggregate: marketplace_instances_aggregate(
      where: { type: { _eq: "dataproduct" } }
    ) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export const ALIGNMENT_CHART_DATA = gql`
  query AlignmentChartData {
    instances: marketplace_instances(
      where: {
        _and: [
          { environment: { priority: { _eq: 0 } } }
          { type: { _eq: "dataproduct" } }
          {
            relations: {
              _and: [
                { name: { _eq: "readsFrom" } }
                { instanceByTargetInstanceId: { kind: { _eq: "component" } } }
              ]
            }
          }
        ]
      }
    ) {
      published_at
      inputPorts: relations(where: { name: { _eq: "readsFrom" } }) {
        data: instanceByTargetInstanceId {
          kind
        }
      }
    }
  }
`;

export const NETWORK_EFFECT = gql`
  query NetworkEffect {
    instances: marketplace_instances(
      where: {
        _and: [
          { environment: { priority: { _eq: 0 } } }
          { type: { _eq: "dataproduct" } }
        ]
      }
    ) {
      published_at
      inputPorts_aggregate: relations_aggregate(
        where: {
          _and: [
            { name: { _eq: "readsFrom" } }
            { instanceByTargetInstanceId: { kind: { _eq: "component" } } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ALL_DATA_PRODUCTS_REVIEWS = gql`
  query GetAllDataProductsReviews {
    instances: marketplace_instances(where: { type: { _eq: "dataproduct" } }) {
      reviews {
        score
      }
    }
    review_aggregate: marketplace_reviews_aggregate(
      where: { instance: { type: { _eq: "dataproduct" } } }
    ) {
      aggregate {
        avg {
          score
        }
      }
    }
  }
`;

export const COUNT_DATA_PRODUCTS_GROUP_BY_ENV = gql`
  query CountDataProductsGroupByEnv {
    marketplace_environments(order_by: { priority: asc }) {
      instances_aggregate(where: { type: { _eq: "dataproduct" } }) {
        aggregate {
          count
        }
      }
      name
    }
    marketplace_instances_aggregate(where: { type: { _eq: "dataproduct" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const DATA_PRODUCTS_USAGE = gql`
  query DataProductsUsage {
    instance_aggregate: marketplace_instances_aggregate(
      where: {
        _and: [
          { environment: { priority: { _eq: 0 } } }
          { type: { _eq: "dataproduct" } }
        ]
      }
    ) {
      aggregate {
        count(distinct: true)
      }
    }
    inputPort_aggregate: marketplace_instances_aggregate(
      where: {
        _and: [
          { environment: { priority: { _eq: 0 } } }
          { type: { _eq: "dataproduct" } }
          {
            relationsByTargetInstanceId: {
              _and: [
                { name: { _eq: "partOfSystem" } }
                {
                  instance: {
                    _and: [
                      {
                        relationsByTargetInstanceId: {
                          name: { _eq: "readsFrom" }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export interface ValueByMonth {
  monthOfYear: string; // in the format MMM YYYY
  value: number;
}

export interface AlignmentByMonth {
  monthOfYear: string;
  totalSources: number;
  totalConsumers: number;
}
