import React from 'react';
import { CustomViewRegister } from '../CustomView';
import { DataPathContextProvider, useDataPath } from '../../context';
import { Grid } from '@material-ui/core';

CustomViewRegister.register({
  id: 'sequence',
  function: function SequenceView(props: Record<string, any>) {
    const list = useDataPath(props.path) || [];
    return list.map((_: any, idx: number) => {
      const index = `${props.path}[${idx}]`;
      return (
        <DataPathContextProvider key={String(idx)} path={index}>
          {props.children}
        </DataPathContextProvider>
      );
    });
  },
});

CustomViewRegister.register({
  id: 'grid_sequence',
  function: function GridSequenceView(props: Record<string, any>) {
    const list = useDataPath(props.path) || [];
    return list.map((_: any, idx: number) => {
      const index = `${props.path}[${idx}]`;
      return (
        <Grid container spacing={props.spacing || 3}>
          <DataPathContextProvider key={String(idx)} path={index}>
            <Grid key={String(idx)} item xs={12}>
              {props.children}
            </Grid>
          </DataPathContextProvider>
        </Grid>
      );
    });
  },
});
