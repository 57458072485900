/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { createContext, ReactNode, useContext } from 'react';

/**
 * The pin state of the sidebar.
 *
 * @public
 */
export type WbSidebarPinState = {
  /**
   * Whether or not the sidebar is pinned to the `open` state. When `isPinned`
   * is `false`, the sidebar opens and closes on hover. When `true`, the
   * sidebar is permanently opened, regardless of user interaction.
   */
  isPinned: boolean;

  /**
   * A function to toggle the pin state of the sidebar.
   */
  toggleSidebarPinState: () => any;
};

const defaultSidebarPinStateContext: WbSidebarPinState = {
  isPinned: true,
  toggleSidebarPinState: () => {},
};

const WbSidebarPinStateContext = createContext<WbSidebarPinState>(
  defaultSidebarPinStateContext,
);

/**
 * Provides state for how the `Sidebar` is rendered
 *
 * @public
 */
export function WbSidebarPinStateProvider(props: {
  children: ReactNode;
  value: WbSidebarPinState;
}) {
  const { children, value } = props;
  return (
    <WbSidebarPinStateContext.Provider value={value}>
      {children}
    </WbSidebarPinStateContext.Provider>
  );
}

/**
 * Hook to read and update sidebar pin state, which controls whether or not the
 * sidebar is pinned open.
 *
 * @public
 */
export const useWbSidebarPinState = (): WbSidebarPinState => {
  return useContext(WbSidebarPinStateContext);
};
