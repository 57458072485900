import { ApolloError, useQuery } from '@apollo/client';
import { ErrorPanel } from '@backstage/core-components';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

import {
  TableContentLoader,
  WbCardContent,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { COUNT_DATA_PRODUCTS_GROUP_BY_ENV } from '../../graphql';
import { MarketplaceDpQuery } from './types/marketplace-dp';

type EnvironmentCount = {
  env: string;
  count: number;
  total: number;
};

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      '& td': {
        padding: '0.5rem 0.5rem',
      },
    },
  }),
);

export const DataSummaryField = (props: {
  title: string;
  value: number;
  total: number;
}) => {
  return (
    <>
      <Typography
        variant="body1"
        component="div"
      >{`${props.title}: `}</Typography>
      <Typography variant="h3" component="div">
        {props.value}
      </Typography>
    </>
  );
};

function parseSummaryData(data: MarketplaceDpQuery): EnvironmentCount[] {
  return data.marketplace_environments.map(aggregate => {
    return {
      env: snakeCaseToTitleCase(aggregate?.name),
      count: aggregate?.instances_aggregate?.aggregate?.count || 0,
      total: data.marketplace_instances_aggregate.aggregate.count,
    };
  });
}

function parseSummaryRows(data: EnvironmentCount[]) {
  return data.map((summary: EnvironmentCount) => (
    <TableRow key={summary.env}>
      <TableCell>
        <DataSummaryField
          key={summary.env}
          title={summary.env}
          value={summary.count}
          total={summary.total}
        />
      </TableCell>
    </TableRow>
  ));
}

export const SummaryKpiCard = () => {
  const classes = useStyles();

  const {
    loading,
    error,
    data,
  }: {
    loading: boolean;
    error?: ApolloError;
    data?: MarketplaceDpQuery;
  } = useQuery(COUNT_DATA_PRODUCTS_GROUP_BY_ENV);

  return (
    <WbWidget title="Data Products" cardStyle={{ height: '100%' }}>
      <WbCardContent style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {!!error && <ErrorPanel error={error} />}
        <Table className={classes.table}>
          <TableBody>
            {!!loading && <TableContentLoader tableCells={1} rows={3} />}
            {!!data && parseSummaryRows(parseSummaryData(data))}
          </TableBody>
        </Table>
      </WbCardContent>
    </WbWidget>
  );
};
