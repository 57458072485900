import React from 'react';
import { CustomViewRegister } from '../CustomView';
import { Box } from '@material-ui/core';
import { WbFieldLabel } from '../WbFieldLabel';
import { WbTagsArray } from '../WbTag';

CustomViewRegister.register({
  id: 'tags',
  function: function TagView(props: Record<string, any>) {
    const tags = (props.value ?? []).map((t: any) => {
      if (typeof t === 'string') return { tagFQN: t };
      return t;
    });

    switch (props.mode) {
      case 'table':
        return <WbTagsArray tags={tags} />;
      default:
    }

    return (
      <Box
        style={{
          marginTop: '10px',
          marginBottom: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        <WbFieldLabel label={props.label || 'Tags'} />
        <WbTagsArray tags={tags} />
      </Box>
    );
  },
});
