import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { RatingHistogram } from './RatingHistogram';
import { RatingStars } from './RatingStars';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rating: {
      fontWeight: 'bold',
      fontSize: theme.typography.fontSize * 1.5,
    },
    ratingsNumber: {
      fontSize: theme.typography.fontSize,
    },
  }),
);

export type RatingsSummaryCardProps = {
  reviews: Array<{ score: number }>;
  ratingScore: number;
  showRatingBars?: boolean;
  showLastRating?: boolean;
};

export const RatingsSummaryCard = (props: RatingsSummaryCardProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginBottom: '12px' }}
        >
          <Grid item className={classes.rating}>
            {props.ratingScore?.toFixed(2)}
          </Grid>
          <Grid item>
            <RatingStars
              rating={props.ratingScore}
              clickable={false}
              variant="medium"
            />
          </Grid>
          <Grid item className={classes.ratingsNumber}>
            {props.reviews?.length} Ratings
          </Grid>
        </Grid>
        <Grid item>
          <RatingHistogram reviews={props.reviews} />
        </Grid>
      </Grid>
    </Grid>
  );
};
