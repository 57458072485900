/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Page, RoutedTabs } from '@backstage/core-components';
import React from 'react';
import { useOutlet } from 'react-router';
import {
  configApiRef,
  useApi,
  useElementFilter,
} from '@backstage/core-plugin-api';
import { UserSettingsAuthProviders } from './AuthProviders';
import { UserSettingsGeneral } from './General';
import { USER_SETTINGS_TAB_KEY, UserSettingsTabProps } from './UserSettingsTab';
import { DataAccessTab } from './DataAccess';
import {
  useIsGuestUser,
  WbBreadcrumb,
  WbBreadcrumbs,
  WbHeader,
} from '@agilelab/plugin-wb-platform';

/**
 * @public
 */
export const SettingsPage = (props: { providerSettings?: JSX.Element }) => {
  const { providerSettings } = props;
  const outlet = useOutlet();
  const configApi = useApi(configApiRef);
  const dataAccess = configApi.getOptionalBoolean(
    'mesh.profileSettings.dataAccess.enabled',
  );

  const { data: isGuestUser } = useIsGuestUser();

  const tabs = useElementFilter(outlet, elements =>
    elements
      .selectByComponentData({
        key: USER_SETTINGS_TAB_KEY,
      })
      .getElements<UserSettingsTabProps>()
      .flatMap(({ props: elemProps }) => {
        return [
          {
            path: elemProps.path,
            title: elemProps.title,
            children: <>{elemProps.children}</>,
          },
        ];
      }),
  );

  const routes = [
    {
      path: '/',
      title: 'General',
      children: <UserSettingsGeneral isGuestUser={!!isGuestUser} />,
    },
    ...(!isGuestUser
      ? [
          {
            path: '/auth-providers',
            title: 'Authentication Providers',
            children: (
              <UserSettingsAuthProviders providerSettings={providerSettings} />
            ),
          },
        ]
      : []),

    ...(!isGuestUser ? tabs : []),
  ];

  if (dataAccess && !isGuestUser) {
    routes.push({
      path: '/data-access',
      title: 'Data Access',
      children: <DataAccessTab />,
    });
  }

  return (
    <Page themeId="home">
      <WbHeader
        title="Settings and Profile"
        breadcrumbs={
          <WbBreadcrumbs aria-label="breadcrumb">
            <WbBreadcrumb>Settings and Profile</WbBreadcrumb>
          </WbBreadcrumbs>
        }
      />

      <RoutedTabs routes={routes} />
    </Page>
  );
};
