/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { createContext, ReactNode, useContext } from 'react';

/**
 * The open state of the sidebar.
 *
 * @public
 */
export type WbSidebarOpenState = {
  /**
   * Whether or not the sidebar is open and full-width. When `false`, the
   * sidebar is "closed" and typically only shows icons with no text.
   */
  isOpen: boolean;

  /**
   * A function to set whether or not the sidebar is open. Pass `true` to open
   * the sidebar. Pass `false` to close it.
   */
  setOpen: (open: boolean) => void;

  /**
   * A function to delete any pending change to the sidebar state before they have effect
   */
  cancelPendingStateChange: () => void;
};

const defaultSidebarOpenStateContext: WbSidebarOpenState = {
  isOpen: false,
  setOpen: () => {},
  cancelPendingStateChange: () => {},
};

const WbSidebarOpenStateContext = createContext<WbSidebarOpenState>(
  defaultSidebarOpenStateContext,
);

/**
 * Provides context for reading and updating sidebar state.
 *
 * @public
 */
export function WbSidebarOpenStateProvider(props: {
  children: ReactNode;
  value: WbSidebarOpenState;
}) {
  const { children, value } = props;
  return (
    <WbSidebarOpenStateContext.Provider value={value}>
      {children}
    </WbSidebarOpenStateContext.Provider>
  );
}

/**
 * Hook to read and update the sidebar's open state, which controls whether or
 * not the sidebar is open and full-width, or closed and only displaying icons.
 *
 * @public
 */
export const useWbSidebarOpenState = (): WbSidebarOpenState => {
  return useContext(WbSidebarOpenStateContext);
};
