import { Content, Page } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React, { ReactNode } from 'react';
import {
  ImportStepper,
  StepperProvider,
  defaultStepper,
} from '../ImportStepper';
import { WbCard, WbCardContent, WbHeader } from '@agilelab/plugin-wb-platform';
import { catalogImportApiRef } from '../../api';

type renderPropParams = {
  appTitle: string;
  hasGithubIntegration: boolean;
  hasPreparePullRequest: boolean;
};

export type GenericImportPageProps = {
  pageTitle: ReactNode | ((params: renderPropParams) => ReactNode);
  cardTitle: string | ((params: renderPropParams) => string);
  importInfo?: ReactNode | ((params: renderPropParams) => ReactNode);
  breadcrumbs?: ReactNode;
  stepper?: StepperProvider;
};

export const GenericImportPage = ({
  stepper,
  pageTitle,
  cardTitle,
  importInfo,
  breadcrumbs,
}: GenericImportPageProps) => {
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptionalString('app.title') || 'Witboost';
  const catalogImportApi = useApi(catalogImportApiRef);
  const hasGithubIntegration = configApi.has('integrations.github');
  const hasPreparePullRequest = !!catalogImportApi.preparePullRequest;

  const renderParams = {
    appTitle,
    hasGithubIntegration,
    hasPreparePullRequest,
  };

  return (
    <Page themeId="home">
      <WbHeader
        title={
          typeof pageTitle === 'function' ? pageTitle(renderParams) : pageTitle
        }
        breadcrumbs={breadcrumbs}
      />
      <Content>
        <WbCard
          title={
            typeof cardTitle === 'function'
              ? cardTitle(renderParams)
              : cardTitle
          }
        >
          <WbCardContent>
            {typeof importInfo === 'function'
              ? importInfo(renderParams)
              : importInfo}
            <ImportStepper defaultStepper={stepper || defaultStepper} />
          </WbCardContent>
        </WbCard>
      </Content>
    </Page>
  );
};
