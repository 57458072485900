import {
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import { useEditorPageContext } from './context/useEditorPageContext';
import { EditorCard } from './EditorCard/EditorCard';
import { TestCard } from './TestCard/TestCard';
import { EditorPageEnvironmentLoader } from './EditorPageEnvironmentLoader';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

const EditorPageContent: React.FC = () => {
  const configApi = useApi(configApiRef);
  const isTestMinimized =
    configApi.getOptionalBoolean('catalog.editor.testMinimized') ?? true;

  const { testCollapsed, setTestCollapsed } = useEditorPageContext();

  React.useEffect(() => {
    if (isTestMinimized) setTimeout(() => setTestCollapsed(true));
  }, [isTestMinimized, setTestCollapsed]);

  return (
    <WbDockableGroup direction="horizontal">
      <WbDockablePanel collapsible>
        <EditorCard />
      </WbDockablePanel>

      <WbDockableResizeHandle direction="horizontal" />
      <WbDockablePanel
        defaultSize={35}
        minSize={35}
        collapsible
        tab={{
          isCollapsed: testCollapsed,
          setCollapsed: isCollapsed => setTestCollapsed(isCollapsed),
          title: 'Test',
          direction: 'horizontal',
        }}
      >
        <TestCard />
      </WbDockablePanel>
    </WbDockableGroup>
  );
};

export const EditorPage: React.FC = () => {
  return (
    <EditorPageEnvironmentLoader>
      <EditorPageContent />
    </EditorPageEnvironmentLoader>
  );
};
