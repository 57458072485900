import { resolvePath } from 'react-router';

export const isLinkActive = (
  location: string,
  to: string,
  caseSensitive?: boolean,
  exact?: boolean,
  extraActivationPaths: string[] = [],
  excludedActivationPaths: string[] = [],
) => {
  let locationPathname = location;

  let activationPaths = [
    ...extraActivationPaths.map(p => resolvePath(p).pathname),
    to,
  ];

  let excludeds = excludedActivationPaths.map(p => resolvePath(p).pathname);

  if (!caseSensitive) {
    locationPathname = locationPathname.toLocaleLowerCase('en-US');
    activationPaths = activationPaths.map(path =>
      path.toLocaleLowerCase('en-US'),
    );
    excludeds = excludeds.map(path => path.toLocaleLowerCase('en-US'));
  }

  if (
    excludeds.some(
      excluded =>
        locationPathname === excluded ||
        (!exact && locationPathname.startsWith(excluded)),
    )
  )
    return false;

  return activationPaths.some(
    path =>
      locationPathname === path ||
      (!exact && locationPathname.startsWith(path)),
  );
};
