import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Grid } from '@material-ui/core';
import React from 'react';
import { RatingsKpiCard } from './RatingsKpiCard';
import { SummaryKpiCard } from './SummaryKpiCard';
import { UsageKpiCard } from './UsageKpiCard';
import { parseOptionalBooleanConfig } from './utils';

export const RealTimeDataCard = () => {
  const configApi = useApi(configApiRef);

  const enabledSummaryKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.summaryKpiCard.enabled',
    ),
  );
  const enabledUsageKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.usageKpiCard.enabled',
    ),
  );
  const enabledRatingsKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.ratingsKpiCard.enabled',
    ),
  );

  if (!enabledSummaryKpiCard && !enabledUsageKpiCard && !enabledRatingsKpiCard)
    return <></>;

  return (
    <WbCard title="Real-Time Data">
      <WbCardContent>
        <Grid container direction="row" spacing={2}>
          {enabledSummaryKpiCard && (
            <Grid item md={4} xs={12}>
              <SummaryKpiCard />
            </Grid>
          )}
          {enabledUsageKpiCard && (
            <Grid item md={4} xs={12}>
              <UsageKpiCard />
            </Grid>
          )}
          {enabledRatingsKpiCard && (
            <Grid item md={4} xs={12}>
              <RatingsKpiCard />
            </Grid>
          )}
        </Grid>
      </WbCardContent>
    </WbCard>
  );
};
