import { ApolloError, useQuery } from '@apollo/client';
import { ErrorPanel } from '@backstage/core-components';
import { Box, useTheme } from '@material-ui/core';
import React from 'react';

import { WbCardContent, WbWidget } from '@agilelab/plugin-wb-platform';
import { Skeleton } from '@material-ui/lab';
import { GET_ALL_DATA_PRODUCTS_REVIEWS } from '../../graphql';
import { RatingsSummaryCard } from '../Rating/RatingsSummaryCard';
import { RatingsQuery } from './types/ratings';

function parseData(data: RatingsQuery) {
  return {
    ratingScore: data.review_aggregate?.aggregate?.avg?.score || 0,
    reviews: data.instances
      .map(instance => instance.reviews)
      .flat(1)
      .map(item => ({
        score: item.score,
      })),
  };
}

export const RatingsKpiCard = () => {
  const theme = useTheme();

  const {
    loading,
    error,
    data,
  }: {
    loading: boolean;
    error?: ApolloError;
    data?: RatingsQuery;
  } = useQuery(GET_ALL_DATA_PRODUCTS_REVIEWS);

  return (
    <WbWidget
      title="Data Usage Consumer Satisfaction"
      cardStyle={{ height: '100%' }}
    >
      <WbCardContent>
        {!!error && <ErrorPanel error={error} />}
        {!!loading && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            {Array(6)
              .fill(1)
              .map((_, index) => (
                <Skeleton
                  key={index}
                  animation="wave"
                  variant="rect"
                  width="100%"
                  height="20px"
                />
              ))}
          </Box>
        )}
        {!!data && (
          <RatingsSummaryCard
            ratingScore={parseData(data).ratingScore || 0}
            reviews={parseData(data).reviews}
            showLastRating={false}
            showRatingBars
          />
        )}
      </WbCardContent>
    </WbWidget>
  );
};
