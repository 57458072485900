import React from 'react';
import { MigrationCard } from './components/MigrationCard/MigrationCard';
import { MigrationContextProvider } from './context/MigrationContext';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { PracticeShaperOverview } from './components/PracticeShaperOverview/PracticeShaperOverview';
import { PracticeShaperGraphProvider } from './context/PracticeShaperGraphContext';

export const PracticeShaperSettingsPage = ({
  importPageRef,
}: {
  importPageRef: string;
}) => {
  const configApi = useApi(configApiRef);
  const migrationEnabled = configApi.getOptionalBoolean(
    'practiceShaper.migration.migrationSectionEnabled',
  );

  return (
    <>
      {migrationEnabled && (
        <MigrationContextProvider>
          <MigrationCard importPageRef={importPageRef} />
        </MigrationContextProvider>
      )}
      <PracticeShaperGraphProvider>
        <PracticeShaperOverview importPageRef={importPageRef} />
      </PracticeShaperGraphProvider>
    </>
  );
};
