import { Box, Switch, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { BooleanTableFilter } from './types';

export const BooleanFilter = (filter: BooleanTableFilter) => {
  const theme = useTheme();
  const [value, setValue] = useState(filter.value);

  React.useEffect(() => {
    setValue(filter.value);
  }, [filter.value]);

  return (
    <Box
      style={{
        height: '45px',
        borderRadius: '4px',
        padding: '6px 8px 6px 12px',
        border: `1px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        cursor: 'pointer',
        background: value
          ? theme.palette.grey[100]
          : theme.palette.background.default,
      }}
      onClick={() => {
        filter.onChange(!value);
      }}
    >
      <Typography
        style={{ fontWeight: value ? '600' : 'normal' }}
        component="span"
      >
        {filter.field}
      </Typography>

      <Switch color="primary" checked={value} name="" />
    </Box>
  );
};
