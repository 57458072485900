import React, { CSSProperties, ReactNode } from 'react';
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';

type WbHeaderProps = {
  pageTitleOverride?: string;
  style?: CSSProperties;
  titleStyle?: CSSProperties;
  title: ReactNode;
  tooltip?: string;
  children?: ReactNode;
  breadcrumbs?: ReactNode;
  subtitle?: string;
};

import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useContent } from '@backstage/core-components';

const useStyles = makeStyles(
  theme => ({
    header: {
      gridArea: 'pageHeader',
      width: '100%',
    },
    headerContent: {
      display: 'flex',
      paddingInline: theme.spacing(3),
      alignItems: 'center',
      height: '56px',
    },
    headerTitleContainer: {
      flex: 1,
    },
    title: {
      wordBreak: 'break-word',
      marginBottom: 0,
    },
  }),
  { name: 'WbHeader' },
);

type HeaderStyles = ReturnType<typeof useStyles>;

type TitleFragmentProps = {
  classes: HeaderStyles;
  pageTitle: string | ReactNode;
  style?: WbHeaderProps['titleStyle'];
  tooltip?: WbHeaderProps['tooltip'];
};

const TitleFragment = ({
  pageTitle,
  classes,
  tooltip,
  style,
}: TitleFragmentProps) => {
  const { contentRef } = useContent();

  const FinalTitle = (
    <Typography
      ref={contentRef}
      tabIndex={-1}
      className={classes.title}
      variant="h4"
      style={style}
    >
      {pageTitle}
    </Typography>
  );

  if (!tooltip) {
    return FinalTitle;
  }

  return (
    <Tooltip title={tooltip} placement="top-start">
      {FinalTitle}
    </Tooltip>
  );
};

/**
 * Witboost main header
 *
 * @public
 *
 */
export const WbHeader = (props: WbHeaderProps) => {
  const {
    children,
    pageTitleOverride,
    style,
    title,
    tooltip,
    breadcrumbs,
    titleStyle,
  } = props;
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptionalString('app.title') || 'Backstage';
  const documentTitle = pageTitleOverride || title;
  const pageTitle = title || pageTitleOverride;
  const titleTemplate = `${documentTitle} | %s | ${appTitle}`;
  const defaultTitle = `${documentTitle} | ${appTitle}`;

  return (
    <>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle} />
      <header style={style} className={classes.header}>
        {breadcrumbs}
        <Box className={classes.headerContent}>
          <Box className={classes.headerTitleContainer}>
            <TitleFragment
              style={titleStyle}
              classes={classes}
              pageTitle={pageTitle}
              tooltip={tooltip}
            />
          </Box>
          {children}
        </Box>
      </header>
    </>
  );
};
