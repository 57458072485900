import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { AccessControlListClient } from '@agilelab/plugin-wb-access-control-common';
import {
  ActionHandlerClient,
  accessControlListApiRef,
  actionHandlerApiRef,
} from '@agilelab/plugin-wb-access-control';
import { rootRouteRef } from './routes';

export const marketplacePlugin = createPlugin({
  id: 'wb-marketplace',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: actionHandlerApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new ActionHandlerClient({ discoveryApi, fetchApi }),
    }),
    createApiFactory({
      api: accessControlListApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new AccessControlListClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const MarketplacePage = marketplacePlugin.provide(
  createRoutableExtension({
    name: 'MarketplacePage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
