import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { NotificationClient } from '@agilelab/plugin-wb-notification-common';

import { notificationApiRef } from './notificationApiRef';

import { rootRouteRef } from './routes';

export const wmNotificationPlugin = createPlugin({
  id: 'wb-notification',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: notificationApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new NotificationClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const NotificationPage = wmNotificationPlugin.provide(
  createRoutableExtension({
    name: 'NotificationPage',
    component: () =>
      import('./components/NotificationsPage').then(m => m.NotificationsPage),
    mountPoint: rootRouteRef,
  }),
);
