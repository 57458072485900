import React from 'react';
import { CustomView, CustomViewComponent } from '@agilelab/plugin-wb-platform';

export const DataProductPage = (props: Record<string, any>) => {
  return (
    <CustomView
      id="builder_system"
      typeId={props.data.entity.spec.type}
      templateId={props.data.entity.spec.mesh.useCaseTemplateId}
      data={props.data}
      actions={props.actions || {}}
    >
      <CustomViewComponent type="catalog_warnings" />
      <CustomViewComponent type="grid_list">
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="include" id="builder_system_general" />
          <CustomViewComponent type="catalog_relations" />
        </CustomViewComponent>
        <CustomViewComponent type="catalog_component_card" title="Components">
          <CustomViewComponent
            type="no_data"
            label="No Components"
            path="components.length"
          >
            <CustomViewComponent type="grid_sequence" path="components">
              <CustomViewComponent type="sub_card" title="{{label}}">
                <CustomViewComponent
                  type="table"
                  path="components"
                  click="showComponent"
                >
                  <CustomViewComponent
                    type="string"
                    label="Name"
                    value="{{ spec.mesh.name if spec.mesh.name else metadata.name }}"
                    width="30%"
                  />
                  <CustomViewComponent
                    type="link"
                    label="Owner"
                    path="owner"
                    width="30%"
                  />
                  <CustomViewComponent
                    type="string"
                    label="Description"
                    path="metadata.description"
                    width="40%"
                  />
                </CustomViewComponent>
              </CustomViewComponent>
            </CustomViewComponent>
          </CustomViewComponent>
        </CustomViewComponent>
      </CustomViewComponent>
    </CustomView>
  );
};
