import { GraphIOTypes } from '@agilelab/plugin-wb-marketplace-common';
import { useQuery } from '@apollo/client';
import { ErrorPanel, Progress } from '@backstage/core-components';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { WbCardContent, WbWidget } from '@agilelab/plugin-wb-platform';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ALIGNMENT_CHART_DATA, AlignmentByMonth } from '../../graphql';
import { getMonthsBetweenDateAndNow } from '../../utils';
import { CustomStatus } from '../CustomStatus/CustomStatus';

function parseData(data: {
  instances: {
    published_at: string;
    inputPorts: { data: { kind: GraphIOTypes } }[];
  }[];
}): AlignmentByMonth[] {
  if (!data.instances || data.instances.length === 0) {
    return [];
  }

  const dpInstances = [...data.instances].sort((a: any, b: any) =>
    b.published_at < a.published_at ? 1 : -1,
  );

  const alignmentsByMonth = dpInstances
    .flatMap(dpInstance =>
      dpInstance.inputPorts.reduce(
        (acc: AlignmentByMonth, obj: { data: { kind: GraphIOTypes } }) => {
          if (obj.data.kind === 'resource') {
            acc.totalSources += 1;
          } else {
            acc.totalConsumers += 1;
          }
          return acc;
        },
        {
          monthOfYear: DateTime.fromISO(dpInstance.published_at).toFormat(
            'MMM yyyy',
          ),
          totalSources: 0,
          totalConsumers: 0,
        },
      ),
    ) // group by month of year
    .reduce((acc: any, obj: AlignmentByMonth) => {
      const key = obj.monthOfYear;
      if (!acc[key]) {
        acc[key] = {
          totalSources: obj.totalSources,
          totalConsumers: obj.totalConsumers,
        };
        return acc;
      }

      acc[key] = {
        totalSources: acc[key].totalSources + obj.totalSources,
        totalConsumers: acc[key].totalConsumers + obj.totalConsumers,
      };
      return acc;
    }, {});

  const startDate = dpInstances.at(0)?.published_at || undefined;
  if (!startDate) {
    return [];
  }

  return getMonthsBetweenDateAndNow(startDate).map((monthOfYear: string) => {
    if (alignmentsByMonth[monthOfYear]) {
      return {
        monthOfYear: monthOfYear,
        totalConsumers: alignmentsByMonth[monthOfYear].totalConsumers,
        totalSources: alignmentsByMonth[monthOfYear].totalSources,
      } as AlignmentByMonth;
    }
    return {
      monthOfYear: monthOfYear,
      totalConsumers: 0,
      totalSources: 0,
    };
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer: {
      position: 'relative',
      width: '100%',
      height: '320px',
    },
    alignmentsChartContainerEmpty: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -100%)',
      padding: '12px 16px',
      borderRadius: '4px',
      background: theme.palette.grey[300],
      color: 'black',
    },
  }),
);

const CustomTooltip = ({
  active,
  payload,
  label: _,
}: TooltipProps<ValueType, NameType>): JSX.Element => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <p
          style={{
            color: theme.palette.text.primary,
            padding: theme.spacing(0, 1),
          }}
        >{`${payload[0].payload.monthOfYear}`}</p>
        <p
          style={{
            color: theme.palette.primary.main,
            padding: theme.spacing(0, 1),
          }}
        >{`Consumer Aligned : ${payload[0].payload.totalConsumers}`}</p>
        <p
          style={{
            color: theme.palette.primary.light,
            padding: theme.spacing(0, 1),
          }}
        >{`Source Aligned : ${payload[0].payload.totalSources}`}</p>
      </div>
    );
  }

  return <></>;
};

export const EnvAlignmentKpiCard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { loading, error, data } = useQuery(ALIGNMENT_CHART_DATA);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ErrorPanel error={error} />;
  }

  const hasData = data.instances && data.instances.length > 0;

  const barColors = theme.palette.charts;

  return (
    <WbWidget
      title="Data Products Types"
      icon={
        <Tooltip title="Ratio between source aligned Data Products and consumer aligned Data Products">
          <InfoOutlinedIcon color="primary" />
        </Tooltip>
      }
      cardStyle={{ height: '100%' }}
    >
      <WbCardContent>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <CustomStatus getColor={() => barColors[0]}>
              Consumer Aligned
            </CustomStatus>
          </Grid>
          <Grid item>
            <CustomStatus getColor={() => barColors[1]}>
              Source Aligned
            </CustomStatus>
          </Grid>
        </Grid>
        <div className={classes.chartContainer}>
          {!hasData && (
            <div className={classes.alignmentsChartContainerEmpty}>
              No data available
            </div>
          )}
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={parseData(data)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="monthOfYear" />
              <YAxis />
              <ChartTooltip
                content={<CustomTooltip />}
                cursor={{ fill: 'transparent' }}
              />
              <Bar dataKey="totalConsumers" stackId="a" fill={barColors[0]} />
              <Bar dataKey="totalSources" stackId="a" fill={barColors[1]} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </WbCardContent>
    </WbWidget>
  );
};
